/**
 * Generate a New Relic dashboard url
 *
 * @param {string} accountId
 * @param {string} appId
 * @returns {string}
 */
export const dashboardUrl = (accountId, appId) =>
  `https://rpm.newrelic.com/accounts/${accountId}/applications/${appId}`;

/**
 *
 * Return a list of environments that has been integrated with New Relic
 *
 * @param {array} environments
 * @returns {array}
 */
export const filterByIntegrated = environments =>
  environments.filter(env => env.newRelicAppId && env.newRelicAccount);
