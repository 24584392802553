import React, { Component } from 'react';
import { Container as UIKitContainer, Card } from '@creditcards/ui-kit-react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import style from './style.module.css';

class Container extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string
  };

  static defaultProps = {
    className: null
  };

  render() {
    const { children, className, ...rest } = this.props;

    return (
      <UIKitContainer
        className={classnames(style.container, className)}
        {...rest}
      >
        <Card>{children}</Card>
      </UIKitContainer>
    );
  }
}

export default Container;
