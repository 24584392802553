import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { GET_PROJECT } from 'containers/Projects/queries';
import Protected from 'components/Protected';
import { makeButton } from 'components/DeleteButton';
import PropTypes from 'prop-types';

import {
  ARCHIVE_ENVIRONMENT,
  DELETE_ENVIRONMENT
} from 'containers/Environments/queries';
import style from '../style.module.css';

const ArchiveButton = makeButton(ARCHIVE_ENVIRONMENT);
const DeleteButton = makeButton(DELETE_ENVIRONMENT);

class Actions extends Component {
  static propTypes = {
    env: PropTypes.shape({
      id: PropTypes.string.isRequired,
      lastDeployment: PropTypes.object,
      privileged: PropTypes.bool,
      providerConfiguration: PropTypes.object
    }).isRequired,
    project: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func
    }).isRequired,
    providerClick: PropTypes.func.isRequired
  };

  render() {
    const {
      project,
      history: { push },
      env: { id, lastDeployment, privileged, providerConfiguration },
      providerClick
    } = this.props;

    const buttonProps = {
      className: style.archiveButton,
      variables: { id },
      refetchQueries: [{ query: GET_PROJECT, variables: { id: project.id } }],
      onSuccess: () => ReactTooltip.hide()
    };

    return (
      <div className={style.actions} data-cy="environment-actions">
        {providerConfiguration ? (
          <Protected hasRole="ADMIN">
            <i
              className="material-icons"
              onClick={providerClick}
              data-tip="Edit Deployment Configuration"
            >
              code
            </i>
          </Protected>
        ) : null}

        <Protected hasRole={privileged ? 'ADMIN' : 'USER'}>
          <i
            className="material-icons"
            onClick={() => push(`../environments/${id}/edit`)}
            data-tip="Edit environment"
          >
            edit
          </i>
        </Protected>

        <Protected hasRole="ADMIN">
          {lastDeployment ? (
            <ArchiveButton
              buttonIcon="archive"
              {...buttonProps}
              buttonProps={{ 'data-tip': 'Archive environment' }}
            />
          ) : (
            <DeleteButton
              {...buttonProps}
              buttonProps={{
                'data-tip': 'Delete environment',
                'data-cy': 'delete-environment-btn'
              }}
            />
          )}
        </Protected>
      </div>
    );
  }
}

export default withRouter(Actions);
