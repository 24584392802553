import createEngine, {
  DiagramModel,
  DefaultNodeModel,
  PortModelAlignment
} from '@projectstorm/react-diagrams';
import React, { Component } from 'react';
import { uniqueId } from 'lodash';
import PropTypes from 'prop-types';
import {
  AdvancedLinkFactory,
  AdvancedPortModel
} from './Factories/LinkFactory';
import Dagre from './Dagre';

class InfraDiagram extends Component {
  static propTypes = {
    serviceDetails: PropTypes.shape({
      desired: PropTypes.number,
      running: PropTypes.number
    }).isRequired
  };

  constructor(props) {
    super(props);

    this.engine = createEngine();
    this.engine.getLinkFactories().registerFactory(new AdvancedLinkFactory());
  }

  createTaskNode = (lbPort, model) => options => {
    const { name, color, portLabel, active } = {
      color: 'rgb(192,255,0)',
      active: true,
      ...options
    };

    const node = new DefaultNodeModel(name, color);
    const port = node.addPort(
      new AdvancedPortModel({
        isIn: true,
        name: uniqueId('container-'),
        alignment: PortModelAlignment.TOP,
        label: portLabel,
        active
      })
    );

    model.addAll(node, lbPort.link(port));
  };

  render() {
    const {
      serviceDetails: { running }
    } = this.props;

    const model = new DiagramModel();

    const lbNode = new DefaultNodeModel('Load Balancer', 'rgb(0,192,255)');
    const lbPort = lbNode.addPort(
      new AdvancedPortModel({
        isIn: false,
        name: 'out-1',
        alignment: PortModelAlignment.BOTTOM,
        label: ''
      })
    );

    const createTaskNode = this.createTaskNode(lbPort, model);

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < running; i++) {
      createTaskNode({
        name: 'Container',
        portLabel: `Active`,
        color: 'rgb(82, 189, 147)'
      });
    }

    model.addAll(lbNode);

    this.engine.setModel(model);

    model.setLocked(true);

    return <Dagre model={model} engine={this.engine} />;
  }
}

export default InfraDiagram;
