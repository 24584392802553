import React, { Component } from 'react';
import { Alert, Modal } from '@creditcards/ui-kit-react';
import { graphql } from '@apollo/react-hoc';
import PropTypes from 'prop-types';
import ProjectSelector from '../../ProjectSelector';
import style from './style.module.css';
import { ADD_PROJECT_TO_GROUP } from '../../../containers/ProjectGroups/queries';

class AddProject extends Component {
  static propTypes = {
    active: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    projectGroup: PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
      projects: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string
        })
      )
    }).isRequired,
    addProjectToGroupResult: PropTypes.shape({
      error: PropTypes.object
    }).isRequired,
    addProjectToGroup: PropTypes.func.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types,react/forbid-prop-types
    refetchQueries: PropTypes.array.isRequired
  };

  state = {
    projects: []
  };

  submit = () => {
    const { addProjectToGroup, onClose, projectGroup } = this.props;
    const { projects } = this.state;

    const addProject = projectId =>
      addProjectToGroup({
        variables: {
          projectId,
          projectGroupId: projectGroup.id
        }
      });

    Promise.all(projects.map(addProject)).then(onClose);
  };

  render() {
    const {
      active,
      onClose,
      addProjectToGroupResult,
      projectGroup
    } = this.props;

    const projectsInGroup = projectGroup.projects.map(p => p.id);

    return (
      <Modal
        small
        active={active}
        closeModal={onClose}
        type="small"
        title={`Add Projects to ${projectGroup.name}`}
        actions={[
          {
            flat: true,
            appearance: 'danger',
            label: 'Cancel',
            onClick: onClose
          },
          {
            raised: true,
            appearance: 'primary',
            label: 'Add',
            onClick: this.submit
          }
        ]}
        theme={{
          body: style.modalBody
        }}
      >
        <React.Fragment>
          {addProjectToGroupResult.error && (
            <Alert
              type="danger"
              message={addProjectToGroupResult.error.message}
            />
          )}
          <ProjectSelector
            filter={project => !projectsInGroup.includes(project.id)}
            autoFocus
            isMulti
            placeholder="Select Projects..."
            onChange={selected => {
              if (Array.isArray(selected)) {
                this.setState({ projects: selected.map(s => s.value) });
              } else {
                this.setState({ projects: [] });
              }
            }}
          />
        </React.Fragment>
      </Modal>
    );
  }
}

export default graphql(ADD_PROJECT_TO_GROUP, {
  name: 'addProjectToGroup',
  options: ({ refetchQueries }) => ({ refetchQueries })
})(AddProject);
