import React, { Component } from 'react';
import { Form } from '@creditcards/react-forms/lib';
import PropTypes from 'prop-types';
import Button from '@creditcards/ui-kit-react/lib/components/Button';
import CodeBuildFields from '../../Providers/CodeBuildFields';
import style from '../style.module.css';

class CodeBuildForm extends Component {
  form = React.createRef();

  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    providerConfig: PropTypes.shape({
      id: PropTypes.string
    }),
    gqlError: PropTypes.shape({
      graphQLErrors: PropTypes.array
    })
  };

  static defaultProps = {
    gqlError: null,
    providerConfig: null
  };

  render() {
    const { providerConfig, onCancel, onSubmit, gqlError } = this.props;
    return (
      <React.Fragment>
        <div className={style.content}>
          <Form ref={this.form} onSubmit={onSubmit}>
            <CodeBuildFields
              providerConfig={providerConfig}
              gqlError={gqlError}
            />
          </Form>
        </div>
        <div className={style.foot}>
          <Button
            raised
            appearance="primary"
            onClick={() => this.form.current.submit()}
          >
            Update
          </Button>
          <Button appearance="danger" onClick={onCancel}>
            Cancel
          </Button>
        </div>
      </React.Fragment>
    );
  }
}

export default CodeBuildForm;
