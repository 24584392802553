import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import './style/global.module.css';
import App from './containers/App';

if ('Notification' in window) Notification.requestPermission();

/**
 * This is where React meets the DOM. Simply render the root component (<App />) in
 * <div id="root"></div>.
 */
ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);
