import React, { FC } from 'react';
import { Alert, Modal } from '@creditcards/ui-kit-react';
import {
  useUpdateProjectGroupMutation,
  GetProjectGroupDocument,
  useGetProjectGroupsQuery,
  ProjectGroup
} from 'generated/graphql';
import { Form } from '@creditcards/react-forms';
import MultiSelect from 'components/Form/MultiSelectInput';
import { ButtonProps } from '@creditcards/ui-kit-react/lib/components/Button';
import get from 'lodash/get';
import style from './style.module.css';

interface ComponentProps {
  active: boolean;
  onClose: () => void;
  projectGroup: Pick<ProjectGroup, 'id' | 'name'>;
}

interface FormProps {
  projectGroupIds: string[];
}

const Component: FC<ComponentProps> = ({ active, onClose, projectGroup }) => {
  const formRef = React.createRef<Form<FormProps>>();
  const { data, error: queryError } = useGetProjectGroupsQuery();
  const projectGroups = get(data, 'projectGroups.entries', []);

  const [updateGroup, { error: mutationError }] = useUpdateProjectGroupMutation(
    {
      refetchQueries: [
        { query: GetProjectGroupDocument, variables: { id: projectGroup.id } }
      ]
    }
  );

  const onSubmit = async ({ projectGroupIds }: FormProps) => {
    const promises = projectGroupIds.map(id =>
      updateGroup({
        variables: {
          id,
          input: { projectGroupId: projectGroup.id }
        }
      })
    );

    try {
      await Promise.all(promises);

      onClose();
    } catch (e) {
      // The use mutation should populate the error if we fail
    }
  };

  return (
    <Modal
      active={active}
      closeModal={onClose}
      type="small"
      title={`Add Groups to ${projectGroup.name}`}
      actions={
        ([
          {
            flat: true,
            appearance: 'danger',
            label: 'Cancel',
            onClick: onClose
          },
          {
            raised: true,
            appearance: 'primary',
            label: 'Add',
            onClick: () => formRef.current && formRef.current.submit()
          }
        ] as unknown) as ButtonProps[]
      }
      theme={{
        body: style.modalBody
      }}
    >
      <>
        {queryError && <Alert type="danger" message={queryError.message} />}
        {mutationError && (
          <Alert type="danger" message={mutationError.message} />
        )}

        <Form ref={formRef} onValidSubmit={onSubmit}>
          <MultiSelect
            autoFocus
            required
            isMulti
            name="projectGroupIds"
            placeholder="Search project group..."
            options={projectGroups
              .filter(
                ({ id, parentProjectGroup }: ProjectGroup) =>
                  !parentProjectGroup && id !== projectGroup.id
              )
              .map(({ id, name }: ProjectGroup) => ({
                value: id,
                label: name
              }))}
          />
        </Form>
      </>
    </Modal>
  );
};

export default Component;
