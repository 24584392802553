/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Protected from 'components/Protected';
import ReferenceInlineAdd from 'components/ReferenceInlineAdd';
import ReferenceInlineEdit from 'components/ReferenceInlineEdit';
import { makeButton } from 'components/DeleteButton';
import { GET_PROJECT, DELETE_REFERENCE } from 'containers/Projects/queries';
import style from './style.module.css';

const DeleteReferenceButton = makeButton(DELETE_REFERENCE);

class References extends Component {
  static propTypes = {
    project: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      references: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
          url: PropTypes.string
        })
      )
    }).isRequired
  };

  state = {
    addingReference: false,
    editingReferenceId: null
  };

  render() {
    const { project } = this.props;
    const { addingReference, editingReferenceId } = this.state;

    return (
      <div className={style.links}>
        {project.references.map(ref => (
          <React.Fragment key={ref.id}>
            <ul className={style.envLinks} data-cy="reference-list">
              <li>
                <div className={style.linkName}>
                  <a href={ref.url} target="_blank" rel="noopener noreferrer">
                    <i className="material-icons">open_in_new</i>
                    {ref.name}
                  </a>
                </div>
                <Protected hasRole="USER">
                  <div className={style.actions}>
                    <i
                      onClick={e => {
                        e.preventDefault();
                        this.setState({ editingReferenceId: ref.id });
                      }}
                      className={classnames('material-icons', style.edit)}
                    >
                      edit
                    </i>
                    <DeleteReferenceButton
                      buttonProps={{ 'data-cy': 'delete-reference-btn' }}
                      variables={{ id: ref.id }}
                      refetchQueries={[
                        { query: GET_PROJECT, variables: { id: project.id } }
                      ]}
                    />
                  </div>
                </Protected>
              </li>
            </ul>
            {editingReferenceId === ref.id ? (
              <ReferenceInlineEdit
                project={project}
                reference={ref}
                onClose={() => this.setState({ editingReferenceId: null })}
              />
            ) : null}
          </React.Fragment>
        ))}

        {addingReference ? (
          <ReferenceInlineAdd
            project={project}
            onClose={() => this.setState({ addingReference: false })}
          />
        ) : (
          <Protected hasRole="USER">
            <div
              onClick={() => this.setState({ addingReference: true })}
              className={style.add}
            >
              <i className="material-icons">add</i>
              <span>Add Reference</span>
            </div>
          </Protected>
        )}
      </div>
    );
  }
}

export default References;
