import React, { Component } from 'react';
import { get, flowRight } from 'lodash';
import BasicLambdaForm from 'components/EnvBox/BasicLambdaForm';
import EcsForm from 'components/EnvBox/EcsForm';
import EcsScheduledTaskForm from 'components/EnvBox/EcsScheduledTaskForm';
import S3Form from 'components/EnvBox/S3Form';
import { graphql } from '@apollo/react-hoc';
import PropTypes from 'prop-types';
import {
  GET_ENV_PROVIDER_CONFIG,
  CREATE_BASIC_LAMBDA_CONFIGURATION,
  UPDATE_BASIC_LAMBDA_CONFIGURATION,
  CREATE_ECS_CONFIGURATION,
  UPDATE_ECS_CONFIGURATION,
  CREATE_S3_CONFIGURATION,
  UPDATE_S3_CONFIGURATION,
  DELETE_PROVIDER_CONFIG,
  CREATE_ECS_SCHEDULED_TASK_CONFIGURATION,
  UPDATE_ECS_SCHEDULED_TASK_CONFIGURATION,
  CREATE_CODEBUILD_CONFIGURATION,
  UPDATE_CODEBUILD_CONFIGURATION
} from '../../../containers/Environments/queries';
import { QueryLoading } from '../../LoadingPage';
import ProviderSelector from '../ProviderSelector';
import style from './style.module.css';
import { makeButton } from '../../DeleteButton';
import Tooltip from '../../Tooltip';
import CodeBuildForm from '../CodeBuildForm';

const DeleteButton = makeButton(DELETE_PROVIDER_CONFIG);

class ProviderForm extends Component {
  form = React.createRef();

  static propTypes = {
    environmentId: PropTypes.string.isRequired,
    updateEcsConfig: PropTypes.func.isRequired,
    updateS3Config: PropTypes.func.isRequired,
    createBasicLambdaConfig: PropTypes.func.isRequired,
    updateBasicLambdaConfig: PropTypes.func.isRequired,
    createEcsConfig: PropTypes.func.isRequired,
    createS3Config: PropTypes.func.isRequired,
    createEcsScheduledTaskConfig: PropTypes.func.isRequired,
    updateEcsScheduledTaskConfig: PropTypes.func.isRequired,
    createCodebuildConfig: PropTypes.func.isRequired,
    updateCodebuildConfig: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    changePane: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
  };

  state = {
    createType: null,
    error: null
  };

  cancel = () => {
    const { changePane, onCancel } = this.props;

    onCancel();
    changePane('latest-deployment');
  };

  submit = create => ({ providerConfiguration }) => {
    const {
      environmentId,
      createBasicLambdaConfig,
      updateBasicLambdaConfig,
      createEcsConfig,
      updateEcsConfig,
      updateS3Config,
      createS3Config,
      createEcsScheduledTaskConfig,
      updateEcsScheduledTaskConfig,
      updateCodebuildConfig,
      createCodebuildConfig,
      onSubmit
    } = this.props;
    const { __typename, ...configuration } = providerConfiguration;

    let mutation;
    let variables;

    // eslint-disable-next-line default-case
    switch (__typename) {
      case 'basic_lambda':
        mutation = create ? createBasicLambdaConfig : updateBasicLambdaConfig;
        variables = {
          environmentId,
          configuration
        };
        break;
      case 'ecs':
        mutation = create ? createEcsConfig : updateEcsConfig;
        variables = {
          environmentId,
          configuration
        };
        break;
      case 's3':
        mutation = create ? createS3Config : updateS3Config;
        variables = {
          environmentId,
          configuration
        };
        break;
      case 'ecs_scheduled_task':
        mutation = create
          ? createEcsScheduledTaskConfig
          : updateEcsScheduledTaskConfig;
        variables = {
          environmentId,
          configuration
        };
        break;
      case 'codebuild':
        mutation = create ? createCodebuildConfig : updateCodebuildConfig;
        variables = {
          environmentId,
          configuration
        };
    }

    mutation({
      variables,
      refetchQueries: [
        {
          query: GET_ENV_PROVIDER_CONFIG,
          variables: {
            id: environmentId
          }
        }
      ]
    })
      .then(onSubmit)
      .catch(error => this.setState({ error }));
  };

  deleteConfig = () => {
    const { environmentId, onCancel } = this.props;

    return (
      <div className={style.delete}>
        <DeleteButton
          variables={{ environmentId }}
          className={style.deleteButton}
          buttonProps={{ 'data-tip': 'Delete Configuration' }}
          onSuccess={onCancel}
          refetchQueries={[
            { query: GET_ENV_PROVIDER_CONFIG, variables: { id: environmentId } }
          ]}
        />
        <Tooltip />
      </div>
    );
  };

  renderProviderForm = ({ data }) => {
    const { environmentId, changePane } = this.props;
    const { createType, error } = this.state;
    const providerConfig = get(data, 'environment.providerConfiguration', {});
    const create = !providerConfig;
    const type = create ? createType : providerConfig.__typename;

    switch (type) {
      case 'BasicLambdaConfiguration':
        return (
          <React.Fragment>
            {!create ? this.deleteConfig() : null}
            <BasicLambdaForm
              environmentId={environmentId}
              configuration={providerConfig}
              onSubmit={this.submit(create)}
              onCancel={this.cancel}
              gqlError={error}
            />
          </React.Fragment>
        );
      case 'EcsConfiguration':
        return (
          <React.Fragment>
            {!create ? this.deleteConfig() : null}
            <EcsForm
              environmentId={environmentId}
              ecsConfiguration={providerConfig}
              onSubmit={this.submit(create)}
              onCancel={this.cancel}
              gqlError={error}
            />
          </React.Fragment>
        );
      case 'S3Configuration':
        return (
          <React.Fragment>
            {!create ? this.deleteConfig() : null}
            <S3Form
              environmentId={environmentId}
              s3Configuration={providerConfig}
              onSubmit={this.submit(create)}
              onCancel={this.cancel}
              gqlError={error}
            />
          </React.Fragment>
        );
      case 'EcsScheduledTaskConfiguration':
        return (
          <React.Fragment>
            {!create ? this.deleteConfig() : null}
            <EcsScheduledTaskForm
              environmentId={environmentId}
              providerConfig={providerConfig}
              onSubmit={this.submit(create)}
              onCancel={this.cancel}
              gqlError={error}
            />
          </React.Fragment>
        );
      case 'CodebuildConfiguration':
        return (
          <React.Fragment>
            {!create ? this.deleteConfig() : null}
            <CodeBuildForm
              environmentId={environmentId}
              providerConfig={providerConfig}
              onSubmit={this.submit(create)}
              onCancel={this.cancel}
              gqlError={error}
            />
          </React.Fragment>
        );
      default:
        return (
          <ProviderSelector
            onSelect={v => this.setState({ createType: v })}
            onCancel={() => changePane('latest-deployment')}
          />
        );
    }
  };

  render() {
    const { environmentId } = this.props;

    return (
      <QueryLoading
        query={GET_ENV_PROVIDER_CONFIG}
        variables={{ id: environmentId }}
      >
        {this.renderProviderForm}
      </QueryLoading>
    );
  }
}

export default flowRight(
  graphql(UPDATE_ECS_CONFIGURATION, { name: 'updateEcsConfig' }),
  graphql(UPDATE_S3_CONFIGURATION, { name: 'updateS3Config' }),
  graphql(CREATE_ECS_CONFIGURATION, { name: 'createEcsConfig' }),
  graphql(CREATE_S3_CONFIGURATION, { name: 'createS3Config' }),
  graphql(CREATE_ECS_SCHEDULED_TASK_CONFIGURATION, {
    name: 'createEcsScheduledTaskConfig'
  }),
  graphql(UPDATE_ECS_SCHEDULED_TASK_CONFIGURATION, {
    name: 'updateEcsScheduledTaskConfig'
  }),
  graphql(CREATE_BASIC_LAMBDA_CONFIGURATION, {
    name: 'createBasicLambdaConfig'
  }),
  graphql(UPDATE_BASIC_LAMBDA_CONFIGURATION, {
    name: 'updateBasicLambdaConfig'
  }),
  graphql(CREATE_CODEBUILD_CONFIGURATION, {
    name: 'createCodebuildConfig'
  }),
  graphql(UPDATE_CODEBUILD_CONFIGURATION, {
    name: 'updateCodebuildConfig'
  })
)(ProviderForm);
