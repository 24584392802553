import React, { Component } from 'react';
import { Alert, Modal } from '@creditcards/ui-kit-react';
import { Form } from '@creditcards/react-forms';
import { Mutation } from '@apollo/react-components';
import {
  CREATE_PROJECT_GROUP,
  GET_PROJECT_GROUPS
} from 'containers/Projects/queries';
import PropTypes from 'prop-types';
import ProjectGroupForm from '../Form';

class CreateGroup extends Component {
  form = React.createRef();

  static propTypes = {
    onClose: PropTypes.func.isRequired,
    active: PropTypes.bool.isRequired
  };

  submit = (group, createProjectGroup) => {
    const { onClose } = this.props;

    createProjectGroup({
      variables: {
        input: group
      }
    })
      .then(onClose)
      .catch(() => {
        // Ignore thrown error since we read the mutation error prop
      });
  };

  render() {
    const { active, onClose } = this.props;
    return (
      <Mutation
        mutation={CREATE_PROJECT_GROUP}
        refetchQueries={[
          {
            query: GET_PROJECT_GROUPS,
            variables: { pageSize: 8 }
          }
        ]}
      >
        {(createProjectGroup, { error }) => (
          <Modal
            small
            active={active}
            closeModal={onClose}
            type="small"
            title="New Group"
            actions={[
              {
                flat: true,
                appearance: 'danger',
                label: 'Cancel',
                onClick: onClose
              },
              {
                raised: true,
                appearance: 'primary',
                label: 'Create',
                onClick: () => this.form.current.submit()
              }
            ]}
          >
            <React.Fragment>
              {error && <Alert type="danger" message={error.message} />}
              <Form
                ref={this.form}
                onValidSubmit={group => this.submit(group, createProjectGroup)}
              >
                <ProjectGroupForm gqlError={error} />
              </Form>
            </React.Fragment>
          </Modal>
        )}
      </Mutation>
    );
  }
}

export default CreateGroup;
