import React, { Component } from 'react';
import { Chip, Paper } from '@creditcards/ui-kit-react';
import { Link, withRouter } from 'react-router-dom';
import { Sparklines, SparklinesCurve } from 'react-sparklines';
import { max, takeRight } from 'lodash';
import PropTypes from 'prop-types';
import styles from '../../pages/Projects/List/style.module.css';
import ProjectBadges from '../ProjectBadges';

class ProjectBox extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        tenant: PropTypes.string.isRequired
      })
    }).isRequired,
    project: PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string,
      description: PropTypes.string,
      id: PropTypes.string.isRequired,
      environments: PropTypes.array,
      position: PropTypes.number
    }).isRequired
  };

  renderEnvTags = ({ environments }) => {
    const {
      match: {
        params: { tenant }
      }
    } = this.props;

    return (
      <div style={{ whiteSpace: 'normal' }}>
        {environments.map(({ id, name }) => (
          <Link key={id} to={`/${tenant}/environments/${id}`}>
            <Chip key={id}>{name}</Chip>
          </Link>
        ))}
      </div>
    );
  };

  renderName = project => {
    const {
      match: {
        params: { tenant }
      }
    } = this.props;
    const { name, id } = project;

    return (
      <span>
        <Link className={styles.title} to={`/${tenant}/projects/${id}`}>
          {name}
        </Link>

        <ProjectBadges project={project} />
      </span>
    );
  };

  renderSparkline = project => {
    const data = takeRight(project.pulse.map(dep => dep.value), 20);
    const maxScale = max(data);

    return (
      <div className={styles.sparkline}>
        <Sparklines data={data} max={maxScale === 0 ? 1 : maxScale}>
          <SparklinesCurve style={{ strokeWidth: 0.5 }} />
        </Sparklines>
      </div>
    );
  };

  render() {
    const { project } = this.props;

    return (
      <Paper className={styles.project}>
        <div>{this.renderName(project)}</div>
        <div>
          {this.renderSparkline(project)}
          <label className={styles.envLabel}>Environments</label>
          {this.renderEnvTags(project)}
        </div>
      </Paper>
    );
  }
}

export default withRouter(ProjectBox);
