import React from 'react';
import classnames from 'classnames';

import Tooltip from 'components/Tooltip';
import style from './style.module.css';

interface HelpTipProps {
  text: string;
}

const HelpTip: React.FC<HelpTipProps> = ({ text }) => (
  <>
    <i className={classnames('material-icons', style.icon)} data-tip={text}>
      help
    </i>
    <Tooltip />
  </>
);

export default HelpTip;
