import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../Form/Checkbox';

class DeploymentStatusOptions extends Component {
  static propTypes = {
    selected: PropTypes.arrayOf([
      'PENDING',
      'STARTED',
      'COMPLETED',
      'FAILED',
      'ERRORED'
    ])
  };

  static defaultProps = {
    selected: []
  };

  render() {
    const { selected } = this.props;

    return (
      <React.Fragment>
        <Checkbox
          name="on[pending]"
          label="pending"
          defaultValue={selected.includes('PENDING')}
        />
        <Checkbox
          name="on[started]"
          label="started"
          defaultValue={selected.includes('STARTED')}
        />
        <Checkbox
          name="on[completed]"
          label="completed"
          defaultValue={selected.includes('COMPLETED')}
        />
        <Checkbox
          name="on[failed]"
          label="failed"
          defaultValue={selected.includes('FAILED')}
        />
        <Checkbox
          name="on[errored]"
          label="errored"
          defaultValue={selected.includes('ERRORED')}
        />
        <Checkbox
          name="on[canceling]"
          label="canceling"
          defaultValue={selected.includes('CANCELING')}
        />
        <Checkbox
          name="on[canceled]"
          label="canceled"
          defaultValue={selected.includes('CANCELED')}
        />
      </React.Fragment>
    );
  }
}

export default DeploymentStatusOptions;
