import React, { Component } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import TextInput from '../../Form/TextInput';
import { QueryLoading } from '../../LoadingPage/index';
import { GET_AWS_PROFILES } from '../../../containers/Admin/queries';
import SelectInput from '../../Form/SelectInput';
import { awsRegions, humanize } from '../../../utils/enums';
import extractFieldError from '../../../utils/gqlErrors';
import CheckBox from '../../Form/Checkbox';
import HelpTip from '../../HelpTip';

class CodeBuildFields extends Component {
  static propTypes = {
    providerConfig: PropTypes.shape({
      projectName: PropTypes.string,
      region: PropTypes.string,
      awsProfile: PropTypes.shape({
        id: PropTypes.string
      }),
      timeout: PropTypes.number.isRequired,
      specifySourceVersion: PropTypes.bool
    }),
    gqlError: PropTypes.shape({
      graphQLErrors: PropTypes.array
    })
  };

  static defaultProps = {
    gqlError: null,
    providerConfig: {}
  };

  getError = field => {
    const { gqlError } = this.props;
    return extractFieldError(gqlError, field);
  };

  render() {
    const { providerConfig } = this.props;

    return (
      <React.Fragment>
        <TextInput
          name="providerConfiguration[__typename]"
          type="hidden"
          defaultValue="codebuild"
        />

        <TextInput
          name="providerConfiguration[projectName]"
          label="Project Name"
          defaultValue={get(providerConfig, 'projectName')}
          required
          error={this.getError('projectName')}
        />

        <SelectInput
          name="providerConfiguration[timeout]"
          label="Timeout (min)"
          defaultValue={get(providerConfig, 'timeout')}
          required
          error={this.getError('timeout')}
          source={[3, 5, 10, 15, 20, 25, 30].map(minutes => ({
            value: minutes * 60,
            label: minutes.toString()
          }))}
        />

        <SelectInput
          name="providerConfiguration[region]"
          label="Region"
          defaultValue={get(providerConfig, 'region')}
          required
          error={this.getError('region')}
          source={awsRegions.map(region => ({
            value: region,
            label: humanize(region)
          }))}
        />

        <QueryLoading query={GET_AWS_PROFILES} fetchPolicy="cache-first">
          {({ data }) => (
            <SelectInput
              name="providerConfiguration[awsProfileId]"
              label="Profile"
              defaultValue={get(providerConfig, 'awsProfile.id')}
              required
              source={data.awsProfiles.map(profile => ({
                value: profile.id,
                label: humanize(profile.name)
              }))}
              error={this.getError('awsProfileId')}
            />
          )}
        </QueryLoading>

        <CheckBox
          name="providerConfiguration[specifySourceVersion]"
          label={
            <React.Fragment>
              Specify Source Version &nbsp;
              <HelpTip text="If checked, sets source version as the artifact name. Please check out the codebuild docs for more information." />
            </React.Fragment>
          }
          defaultValue={get(providerConfig, 'specifySourceVersion', false)}
          error={this.getError('specifySourceVersion')}
          disabled={false}
        />
      </React.Fragment>
    );
  }
}

export default CodeBuildFields;
