import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Window extends Component {
  static propTypes = {
    children: PropTypes.arrayOf(PropTypes.node).isRequired
  };

  state = {
    activePane: null
  };

  /**
   * Sets the active pane. This function is passed as a prop to each panes child
   *
   * @param {String} activePane Id of pane to set active
   */
  changePane = activePane => this.setState({ activePane });

  /**
   * Finds the pane that has an "active" prop on it
   *
   * @param {Array<Pane>} panes
   * @returns {Pane}
   */
  findActivePaneViaProps = panes => panes.find(pane => pane.props.active);

  renderPane = pane =>
    React.cloneElement(pane.props.children, {
      changePane: this.changePane,
      ...pane.props.children.props
    });

  /**
   * When mounting, we need to check for any panes that have been marked as
   * active via the "active" prop. We set that pane as the active.
   */
  componentDidMount = () => {
    const { children } = this.props;

    const activePane = this.findActivePaneViaProps(children);

    if (activePane) {
      this.setState({ activePane: activePane.props.id });
    }
  };

  /**
   * On updating, we check if any panes were set to active by the
   * controlling (parent) component.
   *
   * @param prevProps
   */
  componentDidUpdate = prevProps => {
    const { children } = this.props;
    const { children: prevChildren } = prevProps;
    const { activePane } = this.state;

    const paneFromProps = this.findActivePaneViaProps(children);
    const paneFromPrevProps = this.findActivePaneViaProps(prevChildren);

    const activePaneChangedViaProps = paneFromProps !== paneFromPrevProps;
    const activePaneChanged = paneFromProps.props.id !== activePane;

    // active pane was switched using props
    if (activePaneChangedViaProps && activePaneChanged) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ activePane: paneFromProps.props.id });
    }
  };

  render() {
    const { children } = this.props;
    const { activePane } = this.state;

    const paneToRender = children.find(pane => pane.props.id === activePane);

    return paneToRender ? this.renderPane(paneToRender) : null;
  }
}

export default Window;
export { default as Pane } from './Pane';
