import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ErrorPage from 'pages/Error';
import Overlay from '@creditcards/ui-kit-react/lib/components/Overlay';
import Portal from '@creditcards/ui-kit-react/lib/components/Modal/Portal';

import style from './style.module.css';

class ErrorBoundary extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired
  };

  state = {
    hasError: false,
    error: {}
  };

  componentDidCatch(error) {
    this.setState({ hasError: true, error });

    if (window.NREUM) {
      window.NREUM.noticeError(error);
    }
  }

  render() {
    const { children } = this.props;
    const { hasError, error } = this.state;

    return hasError ? (
      <Portal className={style.wrapper}>
        <Overlay active theme={{ active: style.active }} />
        <ErrorPage
          title={error.title || 'Uh oh!'}
          message={error.message || 'Something went wrong.'}
          icon={error.icon || 'error_outline'}
          onBack={() => {
            this.setState({ error: {}, hasError: false });
          }}
        />
      </Portal>
    ) : (
      children
    );
  }
}

export default ErrorBoundary;
