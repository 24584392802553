import React, { FC, useEffect, useState } from 'react';
import classnames from 'classnames';
import { get, cloneDeep } from 'lodash';
import { buildTagComparisonUrl } from 'utils/github';
import style from './style.module.css';
import { GetTagsQuery, useGetTagsQuery } from '../../generated/graphql';

interface Tag {
  name: string;
  url: string;
  sha: string;
}

interface Props {
  onTagSelect?: (tag: Tag) => void;
  projectId: string;
  environmentId: string | null;
  currentTag: string;
  repoUrl: string;
}

const CompareUrl: FC<
  Pick<Props, 'repoUrl' | 'currentTag'> & { tag: Tag }
> = ({ repoUrl, currentTag, tag }) => {
  if (!currentTag) {
    return null;
  }

  if (tag.name === currentTag) {
    return <span className={style.current}>Current</span>;
  }

  return (
    <span>
      <a
        className={style.compareLink}
        href={buildTagComparisonUrl(repoUrl, currentTag, tag.name)}
        target="_blank"
        rel="noopener noreferrer"
      >
        Compare
        <i className="material-icons">compare_arrows</i>
      </a>
    </span>
  );
};

const TagSelector: FC<Props> = props => {
  const { projectId, environmentId, currentTag, onTagSelect, repoUrl } = props;

  const [page, setPage] = useState<number>(1);
  const [selectedTag, selectTag] = useState<string>();

  const { data, loading, error, fetchMore } = useGetTagsQuery({
    variables: { id: projectId, environmentId }
  });

  useEffect(() => {
    if (page === 1) return;

    fetchMore({
      variables: { page },
      updateQuery: (prev: GetTagsQuery, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        const allTags = cloneDeep(prev);

        if (allTags.project && allTags.project.tags) {
          allTags.project.tags = allTags.project.tags.concat(
            get(fetchMoreResult, 'project.tags', [])
          );
        }

        return allTags;
      }
    });
  }, [page]);

  if (loading) return <div className={style.loading}>Loading...</div>;
  if (error || !data || !data.project) return <p>Unable to load tags.</p>;

  const tags = data.project.tags || [];

  return (
    <div className={style.tagSelector}>
      <ul className={style.tags}>
        {tags.length ? (
          tags.map(tag => (
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
            <li
              key={tag.name}
              className={classnames({
                [style.active]: selectedTag === tag.name
              })}
              onClick={(): void => {
                if (!onTagSelect) return;

                selectTag(tag.name);
                onTagSelect(tag);
              }}
            >
              <div className={style.tagName}>{tag.name}</div>
              <CompareUrl tag={tag} currentTag={currentTag} repoUrl={repoUrl} />
            </li>
          ))
        ) : (
          <li className={style.noTags}>No Tags found</li>
        )}
        {tags.length ? (
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
          <li
            className={style.viewMore}
            onClick={(): void => setPage(page + 1)}
          >
            View More
          </li>
        ) : null}
      </ul>
    </div>
  );
};

export default TagSelector;
