import localforage from 'localforage';
import { useEffect, useState } from 'react';

const PROVIDER_WARNINGS_KEY = 'shield:displayed_provider_warnings';

export type ProviderType =
  | 'EcsConfiguration'
  | 'BasicLambdaConfiguration'
  | 'S3Configuration'
  | 'EcsScheduledTaskConfiguration'
  | 'CodebuildConfiguration';

export default (type: ProviderType): [boolean, () => void] => {
  const [displayedWarnings, setState] = useState<ProviderType[]>([]);
  const providerDisplayed = displayedWarnings.includes(type);

  const setProviderDisplayed = () => {
    if (providerDisplayed) {
      return;
    }

    const warnings = [type, ...displayedWarnings];

    setState(warnings);
    localforage.setItem(PROVIDER_WARNINGS_KEY, warnings);
  };

  useEffect(() => {
    const loadWarnings = async () => {
      let warnings: ProviderType[] = [];

      try {
        const fetched = await localforage.getItem<ProviderType[]>(
          PROVIDER_WARNINGS_KEY
        );

        if (fetched != null) {
          warnings = fetched;
        }
      } catch (e) {
        // Ignore the load error we'll set it to an empty array
      }

      setState(warnings);
    };

    loadWarnings();
  }, []);

  return [providerDisplayed, setProviderDisplayed];
};
