/* eslint-disable max-classes-per-file */
import React, { Component } from 'react';
import Mousetrap from 'mousetrap';
import hoistNonReactStatic from 'hoist-non-react-statics';
import Portal from '@creditcards/ui-kit-react/lib/components/Modal/Portal';
import Overlay from '@creditcards/ui-kit-react/lib/components/Overlay';
import { AuthContext } from '@creditcards/auth0-react';
import PropTypes from 'prop-types';
import TenantSwitcher from './TenantSwitcher';
import ProjectSwitcher from './ProjectSwitcher';
import ProjectGroupSwitcher from './ProjectGroupSwitcher';
import Help from './Help';
import style from './style.module.css';

class Shortcuts extends Component {
  render() {
    return (
      <React.Fragment>
        <TenantSwitcher />
        <ProjectSwitcher />
        <ProjectGroupSwitcher />
        <Help />
      </React.Fragment>
    );
  }
}

class Shortcut extends Component {
  static propTypes = {
    shortcut: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string)
    ]).isRequired,
    children: PropTypes.node.isRequired
  };

  state = {
    active: false
  };

  componentDidMount() {
    const { shortcut } = this.props;

    Mousetrap.bind(shortcut, () => this.setState({ active: true }), 'keyup');
    Mousetrap.bind('esc', this.close);
  }

  componentWillUnmount() {
    const { shortcut } = this.props;

    Mousetrap.unbind(shortcut, 'esc');
  }

  close = () => this.setState({ active: false });

  render() {
    const { children } = this.props;
    const { active } = this.state;

    const child = React.Children.only(children);

    return active ? (
      <AuthContext.Consumer>
        {auth => (
          <Portal className={style.wrapper}>
            <Overlay
              active={active}
              onClick={this.close}
              onEscKeyDown={this.close}
            />
            <div className={style.content}>
              {React.cloneElement(child, { close: this.close, auth })}
            </div>
          </Portal>
        )}
      </AuthContext.Consumer>
    ) : null;
  }
}

export function withShortcut(shortcut) {
  return BaseComponent => {
    const wrapped = props => (
      <Shortcut shortcut={shortcut}>
        <BaseComponent {...props} />
      </Shortcut>
    );

    wrapped.displayName = `withShortcut(${BaseComponent.displayName ||
      BaseComponent.name})`;
    wrapped.WrappedComponent = BaseComponent;

    return hoistNonReactStatic(wrapped, BaseComponent);
  };
}

export default Shortcuts;
