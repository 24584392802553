import React, { Component } from 'react';
import ProjectEnvGridContainer from 'components/ProjectEnvGridContainer';
import ProjectDetails from 'components/ProjectDetails';
import Title from 'components/Title';
import { CardContent } from '@creditcards/ui-kit-react';
import { GET_PROJECT } from 'containers/Projects/queries';
import { QueryLoading } from 'components/LoadingPage';
import Container from 'components/Container';
import PropTypes from 'prop-types';
import styles from './style.module.css';
import { NotFoundError, GraphQLError } from '../../../errors';

class ProjectView extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string.isRequired
      })
    }).isRequired
  };

  renderProject = ({ error, data, subscribeToMore }) => {
    if (error) throw new GraphQLError(error);

    const { project } = data;

    if (!project) throw new NotFoundError();

    return (
      <React.Fragment>
        <Title title={`Project "${project.name}"`} />

        <ProjectDetails project={project} />

        <CardContent className={styles.wrap}>
          <ProjectEnvGridContainer
            project={project}
            subscribeToMore={subscribeToMore}
          />
        </CardContent>
      </React.Fragment>
    );
  };

  render() {
    const { match } = this.props;

    return (
      <Container data-cy="project-view">
        <QueryLoading query={GET_PROJECT} variables={{ id: match.params.id }}>
          {this.renderProject}
        </QueryLoading>
      </Container>
    );
  }
}

export default ProjectView;
