import { useEffect } from 'react';
import { useAppContext } from '../../context';

interface Props {
  title?: string;
  hideTenant?: boolean;
}

export default function Title({ title: pageTitle, hideTenant }: Props) {
  const ctx = useAppContext();

  let title = 'Shield';
  if (pageTitle) {
    title = `${title} - ${pageTitle}`;
  }

  if (ctx.tenant && !hideTenant) {
    title = `${title} (${ctx.tenant})`;
  }

  useEffect(() => {
    document.title = title;
  }, [title]);

  return null;
}
