import React from 'react';
import { Alert } from '@creditcards/ui-kit-react';
import { withAuth } from '@creditcards/auth0-react';
import { Link } from 'react-router-dom';
import { ShieldAuthContextData } from '../../types';
import getUserRole from '../../utils/getUserRole';
import style from './style.module.css';

interface AccessNoticeProps {
  auth: ShieldAuthContextData;
}

const AccessNotice: React.FC<AccessNoticeProps> = ({ auth }) =>
  auth.profile.roles && getUserRole(auth.profile.roles) === 'read-only' ? (
    <Alert
      // @ts-ignore
      theme={{
        alert: style.alert
      }}
      type="warning"
      message={
        <span>
          You have read-only permission for this tenant. Find out how to gain
          additional access <Link to={`/${auth.tenant}/access`}>here.</Link>
        </span>
      }
    />
  ) : null;

export default withAuth(AccessNotice);
