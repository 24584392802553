import React, { FC } from 'react';
import { makeButton } from 'components/DeleteButton';
import {
  GetArchivedEnvironmentsDocument,
  UnarchiveEnvironmentDocument,
  useGetArchivedEnvironmentsQuery
} from '../../generated/graphql';
import { GraphQLError } from '../../errors';

import style from './style.module.css';

const UnarchiveButton: any = makeButton(UnarchiveEnvironmentDocument);

interface Props {
  projectId: string;
}

const ArchivedEnvironments: FC<Props> = ({ projectId }) => {
  const { data, error, loading } = useGetArchivedEnvironmentsQuery({
    variables: { projectId }
  });

  if (loading) return null;
  if (error) throw new GraphQLError(error);
  if (!data || !data.project) return <span>Project not found.</span>;

  const {
    project: { environments }
  } = data;

  return (
    <ul className={style.environments}>
      {!environments || environments.length === 0 ? (
        <li>None</li>
      ) : (
        environments.map(({ name, id }) => (
          <li key={id}>
            <div>{name}</div>
            <div>
              <UnarchiveButton
                buttonIcon="restore_from_trash"
                variables={{ id }}
                refetchQueries={[
                  {
                    query: GetArchivedEnvironmentsDocument,
                    variables: { projectId }
                  }
                ]}
              />
            </div>
          </li>
        ))
      )}
    </ul>
  );
};

export default ArchivedEnvironments;
