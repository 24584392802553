import React, { Component } from 'react';
import { withShortcut } from 'components/Shortcuts';
import { flowRight } from 'lodash';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import style from './style.module.css';
import ProjectSelector from '../ProjectSelector';

class ProjectSwitcher extends Component {
  static propTypes = {
    data: PropTypes.shape({
      projects: PropTypes.array,
      loading: PropTypes.bool
    }).isRequired,
    auth: PropTypes.shape({
      tenant: PropTypes.string
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func
    }).isRequired,
    close: PropTypes.func.isRequired
  };

  render() {
    const {
      auth: { tenant },
      history: { push },
      close
    } = this.props;

    return tenant ? (
      <ProjectSelector
        autoFocus
        menuIsOpen
        isMulti={false}
        placeholder="Switch Project..."
        onChange={({ value }) => {
          push(`/${tenant}/projects/${value}`);

          close();
        }}
      />
    ) : (
      <div className={style.message}>Please select a tenant first.</div>
    );
  }
}

export default flowRight(
  withShortcut('p'),
  withRouter
)(ProjectSwitcher);
