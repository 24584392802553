import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withAuth } from '@creditcards/auth0-react';

class Redirector extends Component {
  static propTypes = {
    auth: PropTypes.shape({
      profile: PropTypes.shape({
        currentTenant: PropTypes.string
      })
    }).isRequired
  };

  render() {
    const {
      auth: {
        profile: { currentTenant }
      }
    } = this.props;
    return (
      <Redirect to={currentTenant ? `/${currentTenant}` : '/select-tenant'} />
    );
  }
}

export default withAuth(Redirector);
