import React, { FC, useState } from 'react';
import Protected from 'components/Protected';
import { DeleteSlackReminderDocument, Environment } from 'generated/graphql';
import { makeButton } from 'components/DeleteButton';
import { GET_ENV } from 'containers/Environments/queries';
import SlackReminderInlineAdd from './SlackReminderInlineAdd';

import style from '../SlackAlerts/style.module.css';

interface Props {
  environment: Environment;
}

const DeleteButton = makeButton(DeleteSlackReminderDocument);

const SlackReminders: FC<Props> = ({ environment }) => {
  const [formActive, setFormActive] = useState(false);
  const reminders = environment.slackReminders || [];

  return (
    <>
      <h4>Slack Reminders</h4>

      {reminders.map((reminder) => (
        <div className={style.alert} key={reminder.id}>
          <div className={style.alertChannel}>
            <div className={style.alertChannelName}>
              <i className="material-icons">alarm</i>
              <span>{reminder.channel}</span>
            </div>

            <Protected hasRole="USER">
              <div className={style.actions}>
                <DeleteButton
                  // @ts-ignore
                  variables={{ id: reminder.id }}
                  refetchQueries={[
                    { query: GET_ENV, variables: { id: environment.id } },
                  ]}
                />
              </div>
            </Protected>
          </div>
        </div>
      ))}

      {formActive ? (
        <SlackReminderInlineAdd
          environmentId={environment.id}
          onClose={() => setFormActive(false)}
        />
      ) : (
        <Protected hasRole="USER">
          <div onClick={() => setFormActive(true)} className={style.add}>
            <i className="material-icons">add</i>
            <span data-cy="add-channel-btn">Add Channel</span>
          </div>
        </Protected>
      )}
    </>
  );
};

export default SlackReminders;
