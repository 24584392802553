import React, { Component } from 'react';
import classnames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Query } from '@apollo/react-components';
import { Button, Paper } from '@creditcards/ui-kit-react';
import ArtifactName from 'components/ArtifactName';
import { GET_DEPLOYMENT } from 'containers/Environments/queries';
import DeploymentLog from 'components/DeploymentLog';

import CancelButton from './CancelButton';
import style from './style.module.css';
import GitRef from '../GitRef';

class Deployment extends Component {
  static propTypes = {
    deployment: PropTypes.shape({
      id: PropTypes.string.isRequired,
      artifactName: PropTypes.string,
      deployer: PropTypes.shape({
        displayName: PropTypes.string
      }),
      status: PropTypes.string
    }).isRequired,
    environment: PropTypes.shape({
      project: PropTypes.shape({
        repository: PropTypes.string
      }),
      privileged: PropTypes.bool
    }).isRequired
  };

  state = {
    showLog: false
  };

  componentDidMount = () => {
    const { deployment } = this.props;
    if (['PENDING', 'STARTED'].includes(deployment.status)) {
      this.setState({ showLog: true });
    }
  };

  renderAutoPromoted = ({ autoPromoted }) => (autoPromoted ? 'Yes' : 'No');

  renderDuration = deployment => {
    const noLogEntries = deployment.logEntries.length === 0;
    const isActive = ['PENDING', 'STARTED'].includes(deployment.status);
    if (isActive || noLogEntries) {
      return '-';
    }

    const firstLogTime = deployment.insertedAt;
    const lastLogTime = deployment.updatedAt;
    const totalSeconds = moment
      .duration(moment(lastLogTime).diff(moment(firstLogTime)))
      .asSeconds();
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = Math.floor(totalSeconds % 60);
    return totalSeconds > 59 ? `${minutes}m ${seconds}s` : `${seconds}s`;
  };

  renderRef = repository => ({ ref }) => (
    <GitRef repo={repository} repoRef={ref} />
  );

  renderDate = ({ insertedAt }) => moment(insertedAt).format('LLL');

  renderStatus = ({ status }) => {
    let icon;
    let className;
    let label;

    switch (status) {
      case 'COMPLETED':
        icon = <i className="material-icons">done</i>;
        className = style.success;
        label = 'Completed';
        break;
      case 'PENDING':
      case 'STARTED':
        icon = <i className={`material-icons ${style.rotate}`}>autorenew</i>;
        className = style.running;
        label = 'Deploying';
        break;
      case 'CANCELING':
        icon = <i className={`material-icons ${style.rotate}`}>autorenew</i>;
        className = style.cancel;
        label = 'Canceling';
        break;
      case 'CANCELED':
        icon = <i className="material-icons">close</i>;
        className = style.cancel;
        label = 'Canceled';
        break;
      default:
        icon = <i className="material-icons">priority_high</i>;
        className = style.error;
        label = 'Errored';
    }

    return (
      <span className={classnames(style.status, className)}>
        {icon}
        <span>{label}</span>
      </span>
    );
  };

  renderLogIcon = row => (
    <i
      className={`material-icons ${style.logToggle}`}
      onClick={() => this.openLog(row)}
    >
      note
    </i>
  );

  render() {
    const { deployment, environment } = this.props;
    const { showLog } = this.state;

    return (
      <Query query={GET_DEPLOYMENT} variables={{ id: deployment.id }}>
        {({ data, loading, error, subscribeToMore }) => {
          if ((!data && loading) || error) return null;

          const detailedDeployment = data.deployment;
          return (
            <li>
              <div
                className={classnames(style.leftSide, {
                  [style.success]: deployment.status === 'COMPLETED',
                  [style.running]: ['PENDING', 'STARTED'].includes(
                    deployment.status
                  ),
                  [style.cancel]: ['CANCELED', 'CANCELING'].includes(
                    deployment.status
                  ),
                  [style.error]: ['FAILED', 'ERRORED'].includes(
                    deployment.status
                  )
                })}
              >
                <div className={style.artifactName}>
                  <ArtifactName artifactName={deployment.artifactName} />
                </div>
                <div className={style.time}>{this.renderDate(deployment)}</div>
              </div>
              <Paper className={style.details}>
                <div>
                  <label>Status</label>
                  {this.renderStatus(deployment)}

                  <CancelButton
                    deployment={deployment}
                    environment={environment}
                  />
                </div>
                <div>
                  <label>Deployed By</label>
                  {deployment.deployer.displayName}
                </div>
                <div>
                  <label>Ref</label>
                  {this.renderRef(environment.project.repository)(deployment)}
                </div>
                <div>
                  <label>Auto promoted</label>
                  {this.renderAutoPromoted(deployment)}
                </div>
                <div>
                  <label>Duration</label>
                  {this.renderDuration(detailedDeployment)}
                </div>
                <div className={style.deploymentLogWrap}>
                  <label>Deployment Log</label>
                  <Button
                    flat
                    appearance="primary"
                    small
                    onClick={() => this.setState({ showLog: !showLog })}
                    icon={showLog ? 'arrow_drop_up' : 'arrow_drop_down'}
                  >
                    {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                    {showLog ? 'Hide' : 'View'} Log
                  </Button>
                  <div
                    className={classnames(style.deploymentLog, {
                      [style.open]: showLog
                    })}
                  >
                    {showLog ? (
                      <DeploymentLog
                        subscribe={['PENDING', 'STARTED', 'CANCELING'].includes(
                          deployment.status
                        )}
                        deployment={detailedDeployment}
                        subscribeToMore={subscribeToMore}
                      />
                    ) : null}
                  </div>
                </div>
              </Paper>
            </li>
          );
        }}
      </Query>
    );
  }
}

export default Deployment;
