import React, { Component } from 'react';
import { Row } from '@creditcards/ui-kit-react';
import ProjectEnvGridItem from 'components/ProjectEnvGridItem';
import PropTypes from 'prop-types';
import { SortableContainer } from 'react-sortable-hoc';
import styles from './style.module.css';

class ProjectEnvGrid extends Component {
  static propTypes = {
    project: PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string,
      description: PropTypes.string,
      id: PropTypes.string.isRequired,
      environments: PropTypes.array,
      position: PropTypes.number
    }).isRequired,
    environments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    isDragging: PropTypes.bool.isRequired,
    subscribeToMore: PropTypes.func.isRequired
  };

  render() {
    const { project, environments, isDragging, subscribeToMore } = this.props;
    return (
      <Row>
        {environments.length === 0 ? (
          <div className={styles.noEnvironments} data-cy="env-missing">
            No environments
          </div>
        ) : (
          environments.map((env, index) => (
            <ProjectEnvGridItem
              env={env}
              index={index}
              isDragging={isDragging}
              key={env.id}
              project={project}
              subscribeToMore={subscribeToMore}
            />
          ))
        )}
      </Row>
    );
  }
}

export default SortableContainer(ProjectEnvGrid);
