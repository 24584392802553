import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import ProjectGroupsView from '../../pages/ProjectGroups/View';
import ProjectGroupsEdit from '../../pages/ProjectGroups/Edit';

class ProjectGroups extends Component {
  static propTypes = {
    match: PropTypes.shape({
      path: PropTypes.string
    }).isRequired
  };

  render() {
    const {
      match: { path }
    } = this.props;

    return (
      <React.Fragment>
        <Switch>
          <Route path={`${path}/:id/edit`} component={ProjectGroupsEdit} />
          <Route path={`${path}/:id`} component={ProjectGroupsView} />
        </Switch>
      </React.Fragment>
    );
  }
}

export default ProjectGroups;
