import React, { useEffect } from 'react';
import { Alert } from '@creditcards/ui-kit-react';
import MultiSelectInput, { Option } from 'components/Form/MultiSelectInput';

import {
  useGetEcsServicesLazyQuery
} from 'generated/graphql';

interface Props {
  clusterId?: string;
  clusterName?: string;
  service?: string | null;
}

function getOptions(ecsServices?: string[] | null): Option[] {
  const services = ecsServices || [];
  services.sort();

  return services.map((service) => ({
    label: service,
    value: service
  }));
}

function getDefaultValue(service?: string | null, ecsServices?: string[] | null): string | null {
  if (service) return service;
  if (ecsServices?.length === 1) return ecsServices[0];
  return null;
}

export default function ServiceSelect({ clusterId, clusterName, service }: Props) {
  const [fetchServices, { loading, data, error, called }] = useGetEcsServicesLazyQuery();

  useEffect(() => {
    if (clusterId) {
      fetchServices({ variables: { clusterId } });
    }
  }, [clusterId]);

  if (error) return <Alert message={error.message} type="danger" />;

  return (
    <MultiSelectInput
      name="providerConfiguration[service]"
      label="Service"
      defaultValue={getDefaultValue(service, data?.ecsServices)}
      options={getOptions(data?.ecsServices)}
      isMulti={false}
      isLoading={loading}
      isDisabled={!clusterId || !called}
      noOptionsMessage={() => {
        const msg = 'No Services Found';
        return clusterName ? `${msg} in "${clusterName}"` : msg;
      }}
      required
    />
  )
}
