import React, { PureComponent } from 'react';
import Alert from '@creditcards/ui-kit-react/lib/components/Alert/index';
import Button from '@creditcards/ui-kit-react/lib/components/Button/index';
import PropTypes from 'prop-types';

import style from '../style.module.css';

class Error extends PureComponent {
  static propTypes = {
    message: PropTypes.string.isRequired,
    clearError: PropTypes.func.isRequired
  };

  render() {
    const { message, clearError } = this.props;

    return (
      <React.Fragment>
        <div className={style.content}>
          <Alert message={message} type="danger" />
        </div>
        <div className={style.foot}>
          <Button flat appearance="primary" onClick={clearError}>
            Back
          </Button>
        </div>
      </React.Fragment>
    );
  }
}

export default Error;
