import gql from 'graphql-tag';

export const CREATE_PROJECT_GROUP = gql`
  mutation($input: CreateProjectGroupInput!) {
    createProjectGroup(input: $input) {
      id
      name
      projects {
        id
      }
    }
  }
`;

export const GET_PROJECT_GROUPS = gql`
  query {
    projectGroups {
      entries {
        id
        name
        projects {
          id
        }
      }
    }
  }
`;

export const GET_PROJECT_GROUP = gql`
  query($id: ID!) {
    projectGroup(id: $id) {
      id
      name
      description

      projectGroups {
        id
        name
      }

      projects {
        id
        name
        repository

        badges {
          url
          imgSrc
        }

        environments {
          name
          id
        }

        pulse {
          value
        }
      }
    }
  }
`;

export const ADD_PROJECT_TO_GROUP = gql`
  mutation($projectId: ID!, $projectGroupId: ID!) {
    addProjectToProjectGroup(
      projectGroupId: $projectGroupId
      projectId: $projectId
    ) {
      id
    }
  }
`;

export const REMOVE_PROJECT_FROM_GROUP = gql`
  mutation($projectId: ID!, $projectGroupId: ID!) {
    removeProjectFromProjectGroup(
      projectGroupId: $projectGroupId
      projectId: $projectId
    ) {
      id
    }
  }
`;

export const UPDATE_PROJECT_GROUP = gql`
  mutation($id: ID!, $input: UpdateProjectGroupInput!) {
    updateProjectGroup(id: $id, input: $input) {
      id
    }
  }
`;

export const DELETE_PROJECT_GROUP = gql`
  mutation($id: ID!) {
    deleteProjectGroup(id: $id)
  }
`;
