import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import ProjectsList from 'pages/Projects/List';
import ProjectsView from 'pages/Projects/View';
import ProjectsNew from 'pages/Projects/New';
import EnvironmentsAdd from 'pages/Environments/Add';
import ProjectsEdit from 'pages/Projects/Edit';
import PropTypes from 'prop-types';

class Projects extends Component {
  static propTypes = {
    match: PropTypes.shape({
      path: PropTypes.string
    }).isRequired
  };

  render() {
    const {
      match: { path }
    } = this.props;

    return (
      <React.Fragment>
        <Switch>
          <Route path={`${path}/new`} component={ProjectsNew} />
          <Route
            path={`${path}/:id/environments/new`}
            component={EnvironmentsAdd}
          />
          <Route path={`${path}/:id/edit`} component={ProjectsEdit} />
          <Route path={`${path}/:id`} component={ProjectsView} />
          <Route exact path={path} component={ProjectsList} />
        </Switch>
      </React.Fragment>
    );
  }
}

export default Projects;
