import React, { FC } from 'react';
import classnames from 'classnames';
import moment from 'moment';
import style from './style.module.css';
import slack from '../../assets/Slack_Mark.svg';

interface User {
  id: string;
  displayName: string;
  username: string;
  email: string;
  slackId: string;
  updatedAt: string;
}

interface AccessAdminCardProps {
  user: User;
}

const AccessAdminCard: FC<AccessAdminCardProps> = ({ user }) => {
  const isActive = moment(user.updatedAt).isAfter(moment().subtract(1, 'week'));
  return (
    <div className={style.admin} key={user.email}>
      <div className={style.activeIndicator}>
        <div
          className={classnames({
            [style.active]: isActive
          })}
          data-tip={
            isActive
              ? 'Active in the last 7 days'
              : 'Not active in the last 7 days'
          }
        />
      </div>
      <div className={style.user}>
        <div className={style.name}>
          <span>{user.displayName}</span>
          {user.slackId ? (
            <a
              href={`slack://user?team=T029AV3QX&id=${user.slackId}`}
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                alt="Slack Channel"
                className={style.slackImage}
                src={slack}
              />
            </a>
          ) : null}
        </div>
        <div className={style.email}>{user.email}</div>
      </div>
    </div>
  );
};

export default AccessAdminCard;
