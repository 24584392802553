import React, { Component } from 'react';
import qs from 'query-string';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

class Redirector extends Component {
  static propTypes = {
    history: PropTypes.shape({
      location: PropTypes.object
    }).isRequired
  };

  state = {
    to: null
  };

  componentDidMount = () => {
    const {
      history: { location }
    } = this.props;

    const parsed = qs.parse(location.search);

    this.setState({ to: parsed.to ? parsed.to : '/' });
  };

  render() {
    const { to } = this.state;

    if (to) return <Redirect to={to} />;

    return null;
  }
}

export default Redirector;
