import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NEW_DEPLOYMENT_LOG_SUBSCRIPTION } from 'containers/Environments/queries';
import moment from 'moment';
import { get } from 'lodash';

import style from './style.module.css';

export default class DeploymentLog extends Component {
  static propTypes = {
    deployment: PropTypes.shape({
      id: PropTypes.string,
      artifactName: PropTypes.string,
      environment: PropTypes.shape({
        name: PropTypes.string,
        project: PropTypes.shape({
          name: PropTypes.string
        })
      })
    }).isRequired,
    subscribeToMore: PropTypes.func.isRequired,
    subscribe: PropTypes.bool
  };

  static defaultProps = {
    subscribe: false
  };

  componentDidMount() {
    const { deployment, subscribeToMore, subscribe } = this.props;

    if (subscribe) {
      this.unsubscribe = subscribeToMore({
        document: NEW_DEPLOYMENT_LOG_SUBSCRIPTION,
        variables: { deploymentId: deployment.id },
        updateQuery: this.onNewEntry
      });
    }
  }

  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
  }

  onNewEntry = (prev, { subscriptionData }) => {
    if (!subscriptionData.data) return prev;

    return {
      deployment: {
        ...prev.deployment,
        logEntries: [
          ...prev.deployment.logEntries,
          subscriptionData.data.newDeploymentLogEntry
        ]
      }
    };
  };

  renderEntry = entry => (
    /* eslint-disable react/jsx-one-expression-per-line */
    <code key={entry.timestamp}>
      <span className={style.time}>
        {moment(entry.timestamp)
          .utc()
          .format()}
      </span>{' '}
      [<span className={style[entry.level]}>{entry.level}</span>]{' '}
      {entry.message}
    </code>
  );

  render() {
    const { deployment } = this.props;

    const entries = get(deployment, 'logEntries', []);

    return (
      <div className={style.log}>
        <code className={style.header}>
          {`| ${deployment.environment.project.name} | ${deployment.environment.name} | ${deployment.artifactName} |`}
          <br />
          <br />
        </code>
        {entries.length ? entries.map(this.renderEntry) : null}
      </div>
    );
  }
}
