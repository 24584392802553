import React, { Component } from 'react';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-boost';
import _ApolloClient from 'apollo-client';
import { ApolloProvider } from '@apollo/react-common';
import { split } from 'apollo-link';
import { getMainDefinition } from 'apollo-utilities';
import { Socket } from 'phoenix';
import { create as createAbsintheSocket } from '@absinthe/socket';
import { createAbsintheSocketLink } from '@absinthe/socket-apollo-link';
import PropTypes from 'prop-types';
import config from 'config';
import { withAuth } from '@creditcards/auth0-react';
import introspectionQueryResultData from 'utils/gql/fragmentTypes';
import { withAppContext } from 'context';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
});

class ApolloClient extends Component {
  static createClient = ({ token, tenant = null }) => {
    const httpLink = createHttpLink({
      uri: `${config.API.BASE_URL}/api/v2/graphql`
    });

    let authHeaders = {};
    let authParams = {};

    if (token) {
      authHeaders = {
        authorization: `Bearer ${token}`,
        'tenant-id': tenant
      };
      authParams = {
        authorization: token,
        tenant
      };
    }

    const authLink = setContext((_, { headers }) => ({
      headers: {
        ...headers,
        ...authHeaders
      }
    }));

    if (ApolloClient.socket) {
      ApolloClient.socket.disconnect();
    }

    ApolloClient.socket = new Socket(`${config.API.WS_URL}/socket`, {
      params: {
        ...authParams
      }
    });

    const absintheLink = createAbsintheSocketLink(
      createAbsintheSocket(ApolloClient.socket)
    );

    const link = split(
      ({ query }) => {
        const { kind, operation } = getMainDefinition(query);

        return kind === 'OperationDefinition' && operation === 'subscription';
      },
      absintheLink,
      authLink.concat(httpLink)
    );

    const defaultOptions = {
      watchQuery: {
        fetchPolicy: 'cache-and-network'
      },
      query: {
        fetchPolicy: 'cache-first'
      }
    };

    return new _ApolloClient({
      link,
      cache: new InMemoryCache({ fragmentMatcher }),
      defaultOptions
    });
  };

  static propTypes = {
    children: PropTypes.node.isRequired,
    token: PropTypes.string.isRequired,
    tenant: PropTypes.string.isRequired
  };

  state = {
    client: null,
    // eslint-disable-next-line react/no-unused-state
    token: '',
    // eslint-disable-next-line react/no-unused-state
    tenant: ''
  };

  static getDerivedStateFromProps(props, state) {
    const { token, tenant } = props;

    if (token !== state.token || tenant !== state.tenant) {
      return {
        client: ApolloClient.createClient({ token, tenant }),
        tenant,
        token
      };
    }

    return null;
  }

  componentDidMount = () => {
    const { token, tenant } = this.props;

    /*
      In a world where we already have the token, just fire up the client on mount
      (this is never the case in the current setup, but this is here just in case)
     */
    if (token) {
      this.setState({ client: ApolloClient.createClient({ tenant, token }) });
    }
  };

  componentWillUnmount = () => {
    if (ApolloClient.socket) ApolloClient.socket.disconnect();
  };

  render() {
    const { children } = this.props;
    const { client } = this.state;

    /*
      A client is necessary for ApolloProvider, and ApolloProvider is necessary
      for any GQL activities (pretty much the entire app)
     */
    return client ? (
      <ApolloProvider client={client}>{children}</ApolloProvider>
    ) : null;
  }
}

ApolloClient.socket = null;

export default withAppContext(withAuth(ApolloClient));
