export default function getWebHookType(url: string) :string {
    if (!url) {
        return 'https'
    }
    try {
        const { protocol } = new URL(url)
        return protocol.replace(/:?$/, "")
    } catch {
        // continue regardless of error
    }

    return 'https'
}