import gql from 'graphql-tag';

// eslint-disable-next-line import/prefer-default-export
export const SET_CURRENT_TENANT = gql`
  mutation($tenant: String!) {
    setCurrentTenant(tenant: $tenant) {
      id
    }
  }
`;
