import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Protected from 'components/Protected';
import WebHookAdd from './Add';
import style from './style.module.css';
import WebHook from './WebHook';

class WebHooks extends Component {
  static propTypes = {
    environment: PropTypes.shape({
      id: PropTypes.string.isRequired,
      webHooks: PropTypes.arrayOf(PropTypes.object)
    })
  };

  static defaultProps = {
    environment: {}
  };

  state = {
    showAddForm: false,
    newSigningKey: null,
    newWebHookId: null
  };

  render() {
    const { environment } = this.props;
    const { showAddForm, newSigningKey, newWebHookId } = this.state;

    return (
      <React.Fragment>
        <h4>Web Hooks</h4>
        {environment.webHooks.map(webHook => (
          <WebHook
            webHook={webHook}
            environment={environment}
            newSigningKey={newSigningKey}
            newWebHookId={newWebHookId}
          />
        ))}

        {showAddForm ? (
          <WebHookAdd
            environmentId={environment.id}
            onClose={newWebHook => {
              let newState = {
                showAddForm: false
              };

              if (newWebHook) {
                newState = {
                  ...newState,
                  newSigningKey: newWebHook.signingKey,
                  newWebHookId: newWebHook.webHook.id
                };
              }

              this.setState(newState);
            }}
          />
        ) : (
          <Protected hasRole="USER">
            <div
              onClick={() => this.setState({ showAddForm: true })}
              className={style.add}
            >
              <i className="material-icons">add</i>
              <span data-cy="add-web-hook-btn">Add Web Hook</span>
            </div>
          </Protected>
        )}
      </React.Fragment>
    );
  }
}

export default WebHooks;
