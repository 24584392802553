// Defaults (production)
const config = {
  API: {
    BASE_URL: '', // relative
    WS_URL: ''
  },
  AUTH: {
    DOMAIN: 'redventures-prod.auth0.com',
    CLIENT_ID: '5zK3KxfarjtduyB70tvU2XvSs62hoDsA',
    AUDIENCE: 'https://prod-shield'
  },
  LAUNCH_DARKLY: {
    CLIENT_ID: '5cb10240978a2f0822feb99e'
  },
  TENANT: 'rv_atx' // NOTE: I'm a hack too!!
};

if (process.env.NODE_ENV === 'development') {
  // Dev-specific values

  config.API.BASE_URL = 'http://localhost:4000';
  config.API.WS_URL = 'ws://localhost:4000';

  config.AUTH.DOMAIN = 'redventures-dev.auth0.com';
  config.AUTH.CLIENT_ID = 'Z9eT43yIImAoS5ni7x6w72iHL7qeAu11';
  config.AUTH.AUDIENCE = 'https://dev-shield';

  config.LAUNCH_DARKLY.CLIENT_ID = '5cb10240978a2f0822feb99d';

  // NOTE: Im a hack!!
  config.TENANT = 'dev';
}

if (process.env.REACT_APP_CYPRESS != null) {
  config.API.BASE_URL = 'https://shield-qa.ccards.io';
  config.API.WS_URL = 'ws://shield-qa.ccards.io';
  config.TENANT = 'dev';
}

export default config;
