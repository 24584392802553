import React, { Component } from 'react';
import Alert from '@creditcards/ui-kit-react/lib/components/Alert';
import PropTypes from 'prop-types';
import { UPDATE_WEB_HOOK } from 'containers/Environments/queries';
import { MutationLoading } from 'components/LoadingPage';
import { getStatusEnumsFromForm } from 'utils/enums';
import WebHookForm from './Form';

import style from './style.module.css';

class WebHookInlineEdit extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    webHook: PropTypes.shape({
      id: PropTypes.string,
      url: PropTypes.string,
      on: PropTypes.array
    }).isRequired
  };

  render() {
    const { onClose, webHook } = this.props;

    return (
      <div className={style.container}>
        <MutationLoading mutation={UPDATE_WEB_HOOK} onCompleted={onClose}>
          {(updateWebHook, { error }) => (
            <React.Fragment>
              {error ? <Alert type="danger" message={error.message} /> : null}
              <div className={style.heading}>Edit Alert</div>
              <WebHookForm
                edit
                gqlError={error}
                onCancel={onClose}
                webHook={webHook}
                onSubmit={form =>
                  updateWebHook({
                    variables: {
                      id: webHook.id,
                      webHook: {
                        url: form.url,
                        on: getStatusEnumsFromForm(form.on),
                        awsProfileId: form.awsProfileId || null
                      }
                    }
                  })
                }
              />
            </React.Fragment>
          )}
        </MutationLoading>
      </div>
    );
  }
}

export default WebHookInlineEdit;
