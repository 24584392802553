/* eslint-disable react/jsx-one-expression-per-line */
import React, { Component } from 'react';
import { withShortcut } from 'components/Shortcuts';
import { Card, Table } from '@creditcards/ui-kit-react';
import style from './style.module.css';

const shortcuts = [
  { key: 'g', usage: 'Go to Project Group' },
  { key: 'p', usage: 'Go to Project' },
  { key: 't', usage: 'Go to Tenant' },
  { key: '?', usage: 'Display help' }
];

class Help extends Component {
  render() {
    return (
      <Card className={style.card}>
        <Table
          className={style.table}
          zebra
          hidePagination
          paginationSize={Infinity}
          columns={[
            {
              heading: 'Shortcut',
              sort: false,
              className: style.shortcut,
              template: row => <kbd className={style.kbd}>{row.key}</kbd>
            },
            {
              sort: false,
              path: 'usage'
            }
          ]}
          data={shortcuts}
        />
      </Card>
    );
  }
}

export default withShortcut('?')(Help);
