import React from 'react';
import { withShortcut } from 'components/Shortcuts';
import { flowRight } from 'lodash';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import style from './style.module.css';
import ProjectGroupSelector from '../ProjectGroupSelector';

interface Props extends RouteComponentProps {
  close: () => void;
  auth: {
    tenant: string;
  };
}

const projectGroupSwitcher = ({
  history: { push },
  close,
  auth: { tenant }
}: Props) =>
  tenant ? (
    <ProjectGroupSelector
      autoFocus
      menuIsOpen
      isMulti={false}
      placeholder="Switch Project Group..."
      onChange={({ value }: any) => {
        push(`/${tenant}/project-groups/${value}`);

        close();
      }}
    />
  ) : (
    <div className={style.message}>Please select a tenant first.</div>
  );

export default flowRight(
  withShortcut('g'),
  withRouter
)(projectGroupSwitcher);
