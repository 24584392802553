import React, { Component } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import TextInput from '../../Form/TextInput';
import { QueryLoading } from '../../LoadingPage/index';
import { GET_AWS_PROFILES } from '../../../containers/Admin/queries';
import SelectInput from '../../Form/SelectInput';
import { awsRegions, humanize } from '../../../utils/enums';
import extractFieldError from '../../../utils/gqlErrors';

class EcsScheduledTaskFields extends Component {
  static propTypes = {
    providerConfig: PropTypes.shape({
      taskFamily: PropTypes.string,
      region: PropTypes.string,
      profileId: PropTypes.string
    }),
    gqlError: PropTypes.shape({
      graphQLErrors: PropTypes.array
    })
  };

  static defaultProps = {
    gqlError: null,
    providerConfig: {}
  };

  getError = field => {
    const { gqlError } = this.props;
    return extractFieldError(gqlError, field);
  };

  render() {
    const { providerConfig } = this.props;

    return (
      <React.Fragment>
        <TextInput
          name="providerConfiguration[__typename]"
          type="hidden"
          defaultValue="ecs_scheduled_task"
        />

        <TextInput
          name="providerConfiguration[taskFamily]"
          label="Task Family"
          defaultValue={get(providerConfig, 'taskFamily')}
          required
          error={this.getError('taskFamily')}
        />

        <TextInput
          name="providerConfiguration[image]"
          label="Image"
          defaultValue={get(providerConfig, 'image')}
          required
          error={this.getError('image')}
        />

        <SelectInput
          name="providerConfiguration[region]"
          label="Region"
          defaultValue={get(providerConfig, 'region')}
          required
          error={this.getError('region')}
          source={awsRegions.map(region => ({
            value: region,
            label: humanize(region)
          }))}
        />

        <QueryLoading query={GET_AWS_PROFILES} fetchPolicy="cache-first">
          {({ data }) => (
            <SelectInput
              name="providerConfiguration[awsProfileId]"
              label="Profile"
              defaultValue={get(providerConfig, 'awsProfile.id')}
              required
              source={data.awsProfiles.map(profile => ({
                value: profile.id,
                label: humanize(profile.name)
              }))}
              error={this.getError('awsProfileId')}
            />
          )}
        </QueryLoading>
      </React.Fragment>
    );
  }
}

export default EcsScheduledTaskFields;
