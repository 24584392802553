export default (title, message) => {
  if (!('Notification' in window)) return;

  // eslint-disable-next-line no-new
  new Notification(title, {
    body: message,
    icon: 'https://shield.redventures.io/favicon.ico',
    image: 'https://shield.redventures.io/favicon.ico'
  });
};
