import React, { Component } from 'react';

import { GET_ENV } from 'containers/Environments/queries';

import PropTypes from 'prop-types';
import { QueryLoading } from 'components/LoadingPage';
import { withRoutedPagination } from 'components/RoutedPagination';
import {
  Breadcrumbs,
  CardHead,
  Crumb,
  Tabs,
  Tab,
  Button,
  Alert
} from '@creditcards/ui-kit-react';
import { Link } from 'react-router-dom';
import { graphql } from '@apollo/react-hoc';
import Title from 'components/Title';
import DeploymentList from '../../../components/DeploymentList';
import Container from '../../../components/Container';
import SlackAlertSubscribe from '../../../components/SlackAlertSubscribe';
import TaskDefinitionDetails from '../../../components/TaskDefinitionDetails';
import Protected from '../../../components/Protected';
import { GET_FEATURES } from '../../../containers/Admin/queries';
import style from './style.module.css';
import { GraphQLError, NotFoundError } from '../../../errors';

const makeIsFeatureEnabled = features => key => {
  const feature = features.find(f => f.key === key);

  return feature ? feature.value : false;
};

class EnvironmentsView extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string.isRequired
      })
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func
    }).isRequired,
    // This is technically required, but PropTypes doesn't recognize it coming
    // from RoutedPagination
    changePage: PropTypes.func,
    page: PropTypes.number,
    features: PropTypes.shape({
      loading: PropTypes.bool,
      error: PropTypes.object,
      tenantFeatures: PropTypes.arrayOf(PropTypes.object)
    }).isRequired
  };

  static defaultProps = {
    changePage: () => {
      // do nothing
    },
    page: 1
  };

  state = {
    tab: 0
  };

  updatePage = pageNumber => {
    const { changePage } = this.props;

    changePage(pageNumber);
  };

  render() {
    const {
      match,
      page,
      history: { push },
      features
    } = this.props;
    const { tab } = this.state;

    if (features.loading) return null;
    if (features.error)
      return <Alert message={features.error.message} type="danger" />;

    const isFeatureEnabled = makeIsFeatureEnabled(features.tenantFeatures);

    return (
      <QueryLoading
        query={GET_ENV}
        fetchPolicy="network-only"
        variables={{ id: match.params.id, page }}
      >
        {({ data, error, subscribeToMore }) => {
          if (error) throw new GraphQLError(error);
          const { environment } = data;
          if (!environment) throw new NotFoundError();

          const { project, providerConfiguration } = environment;

          const tabs = [
            <Tab label="Deployment History" key="deployment-history">
              <DeploymentList
                subscribeToMore={subscribeToMore}
                environment={environment}
                updatePage={this.updatePage}
              />
            </Tab>
          ];

          if (
            providerConfiguration &&
            providerConfiguration.__typename === 'EcsConfiguration'
          ) {
            tabs.push(
              <Tab label="ECS Details" key="ecs-details">
                {isFeatureEnabled('ECS_DETAILS') ? (
                  <TaskDefinitionDetails environmentId={environment.id} />
                ) : (
                  <Alert
                    message="This feature is disabled for this tenant."
                    type="danger"
                  />
                )}
              </Tab>
            );
          }

          return (
            <Container>
              <Title title={`${project.name} - Environment "${environment.name}"`} />

              <CardHead className={style.pageHead}>
                <Breadcrumbs>
                  <Crumb>
                    <Link to="../projects">Projects</Link>
                  </Crumb>
                  <Crumb>
                    <Link to={`../projects/${project.id}`}>{project.name}</Link>
                  </Crumb>
                  <Crumb active>{environment.name}</Crumb>
                </Breadcrumbs>
                <div className={style.actions}>
                  <Protected
                    hasRole={environment.privileged ? 'ADMIN' : 'USER'}
                  >
                    <Button
                      appearance="primary"
                      flat
                      onClick={() => push(`./${match.params.id}/edit`)}
                      icon="edit"
                    >
                      Edit Environment
                    </Button>
                  </Protected>
                  <SlackAlertSubscribe environment={environment} />
                </div>
              </CardHead>
              <Tabs
                disableTextAnimation
                index={tab}
                onChange={index => this.setState({ tab: index })}
                theme={{
                  navigation: style.tabNavigation,
                  label: style.tabLabel,
                  pointer: style.tabPointer
                }}
              >
                {tabs}
              </Tabs>
            </Container>
          );
        }}
      </QueryLoading>
    );
  }
}

export default graphql(GET_FEATURES, { name: 'features' })(
  withRoutedPagination(EnvironmentsView)
);
