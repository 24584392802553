import React, { Component } from 'react';
import Container from 'components/Container';
import PropTypes from 'prop-types';
import {
  Alert,
  Card,
  CardHead,
  CardContent,
  Button,
  Row,
  Col
} from '@creditcards/ui-kit-react';
import { graphql } from '@apollo/react-hoc';
import ProjectGroupForm from 'components/ProjectGroups/Form';
import Title from 'components/Title';
import { Form } from '@creditcards/react-forms';
import { flowRight } from 'lodash';
import {
  GET_PROJECT_GROUP,
  UPDATE_PROJECT_GROUP
} from 'containers/ProjectGroups/queries';
import ProjectGroupProjectsList from 'components/ProjectGroups/ProjectsList';
import GroupProjectGroupsList from 'components/ProjectGroups/ProjectGroupsList';
import style from './style.module.css';
import { NotFoundError } from '../../../errors';

class ProjectGroupsEdit extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string.isRequired
      })
    }).isRequired,
    data: PropTypes.shape({
      error: PropTypes.shape({
        message: PropTypes.string
      }),
      loading: PropTypes.bool,
      projectGroup: PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.string
      })
    }).isRequired,
    updateProjectGroup: PropTypes.func.isRequired,
    updateProjectGroupResult: PropTypes.shape({
      error: PropTypes.object
    }).isRequired
  };

  submit = id => input => {
    const { history, updateProjectGroup } = this.props;

    updateProjectGroup({
      variables: {
        id,
        input
      }
    }).then(() => history.push('./'));
  };

  render() {
    const { history, data, updateProjectGroupResult } = this.props;

    if (data.loading) return null;
    if (data.error) return <Alert type="danger" message={data.error.message} />;

    const { projectGroup } = data;

    if (!projectGroup) throw new NotFoundError();

    return (
      <Container>
        <Title title={`Edit Project Group "${projectGroup.name}"`} />

        <CardHead className={style.header}>
          <div>{projectGroup.name}</div>
          <div>
            <Button
              flat
              appearance="primary"
              onClick={() => history.push('./')}
              icon="arrow_back"
            >
              Back to Project Group
            </Button>
          </div>
        </CardHead>
        <CardContent>
          <Row>
            <Col sm={6}>
              <Card>
                <CardHead>Group Details</CardHead>
                <CardContent>
                  {updateProjectGroupResult.error && (
                    <Alert
                      message={updateProjectGroupResult.error.message}
                      type="danger"
                    />
                  )}
                  <Form onValidSubmit={this.submit(projectGroup.id)}>
                    <ProjectGroupForm
                      gqlError={updateProjectGroupResult.error}
                      projectGroup={projectGroup}
                    />
                    <Button
                      name="save"
                      disabled={data.loading}
                      raised
                      appearance="primary"
                      type="submit"
                    >
                      Update
                    </Button>
                  </Form>
                </CardContent>
              </Card>
            </Col>

            <Col sm={6}>
              <ProjectGroupProjectsList projectGroup={projectGroup} />
              <GroupProjectGroupsList projectGroup={projectGroup} />
            </Col>
          </Row>
        </CardContent>
      </Container>
    );
  }
}

export default flowRight(
  graphql(GET_PROJECT_GROUP, {
    options: props => ({
      variables: {
        id: props.match.params.id
      }
    })
  }),
  graphql(UPDATE_PROJECT_GROUP, {
    name: 'updateProjectGroup'
  })
)(ProjectGroupsEdit);
