import React, { Component } from 'react';
import { MultiSelect } from '@creditcards/ui-kit-react';
import { withShortcut } from 'components/Shortcuts';
import { flowRight } from 'lodash';
import { graphql } from '@apollo/react-hoc';
import { GET_TENANTS } from 'containers/App/queries';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

class TenantSwitcher extends Component {
  static propTypes = {
    data: PropTypes.shape({
      projects: PropTypes.array,
      loading: PropTypes.bool,
      tenants: PropTypes.arrayOf(PropTypes.string)
    }).isRequired,
    auth: PropTypes.shape({
      tenant: PropTypes.string
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func
    }).isRequired,
    close: PropTypes.func.isRequired
  };

  render() {
    const {
      data: { tenants, loading },
      history: { push },
      close
    } = this.props;

    if (loading) return '...';

    return (
      <MultiSelect
        autoFocus
        menuIsOpen
        isMulti={false}
        placeholder="Switch Tenant..."
        options={tenants.map(t => ({ label: t, value: t }))}
        onChange={({ value }) => {
          push(`/${value}`);

          close();
        }}
      />
    );
  }
}

export default flowRight(
  withShortcut('t'),
  graphql(GET_TENANTS),
  withRouter
)(TenantSwitcher);
