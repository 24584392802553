import React, { Component } from 'react';
import { Col } from '@creditcards/ui-kit-react';
import EnvBox from 'components/EnvBox';
import PropTypes from 'prop-types';
import { SortableElement } from 'react-sortable-hoc';
import style from './style.module.css';

class ProjectEnvGridItem extends Component {
  static propTypes = {
    env: PropTypes.shape({
      id: PropTypes.string
    }),
    isDragging: PropTypes.bool.isRequired,
    project: PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string,
      description: PropTypes.string,
      id: PropTypes.string.isRequired
    }),
    subscribeToMore: PropTypes.func.isRequired
  };

  static defaultProps = {
    env: {},
    project: {}
  };

  render() {
    const { env, isDragging, project, subscribeToMore } = this.props;
    return (
      <Col lg={4} md={6} xs={12} className={style.envSummary} key={env.id}>
        <EnvBox
          env={env}
          isDragging={isDragging}
          project={project}
          subscribeToMore={subscribeToMore}
        />
      </Col>
    );
  }
}

export default SortableElement(ProjectEnvGridItem);
