import React from 'react';
import PropTypes from 'prop-types';
import ProjectEnvGrid from 'components/ProjectEnvGrid';
import arrayMove from 'array-move';
import { withRouter } from 'react-router-dom';
import { graphql } from '@apollo/react-hoc';
import { GET_PROJECT } from 'containers/Projects/queries';
import { UPDATE_ENV_POSITION } from 'containers/Environments/queries';
import gridItemStyles from 'components/ProjectEnvGridItem/style.module.css';

class ProjectEnvGridContainer extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string.isRequired
      })
    }).isRequired,
    project: PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string,
      description: PropTypes.string,
      id: PropTypes.string.isRequired,
      environments: PropTypes.array,
      position: PropTypes.number
    }).isRequired,
    subscribeToMore: PropTypes.func.isRequired,
    updateEnvironmentPosition: PropTypes.func.isRequired
  };

  state = {
    // eslint-disable-next-line react/destructuring-assignment
    environments: this.props.project.environments,
    isDragging: false,
    // eslint-disable-next-line react/destructuring-assignment, react/no-unused-state
    prevProject: this.props.project
  };

  static getDerivedStateFromProps = (props, state) => {
    if (props.project !== state.prevProject) {
      return {
        environments: props.project.environments,
        prevProject: props.project
      };
    }
    return null;
  };

  onEnvDrag = () => {
    this.setState({ isDragging: true });
  };

  onEnvDrop = ({ oldIndex, newIndex }) => {
    const { match, updateEnvironmentPosition } = this.props;
    const { environments } = this.state;
    const env = environments[oldIndex];
    this.setState(prevState => ({
      environments: arrayMove(prevState.environments, oldIndex, newIndex),
      isDragging: false
    }));
    updateEnvironmentPosition({
      variables: {
        id: env.id,
        input: { position: environments[newIndex].position }
      },
      refetchQueries: [
        { query: GET_PROJECT, variables: { id: match.params.id } }
      ]
    });
  };

  render() {
    const { project, subscribeToMore } = this.props;
    const { environments, isDragging } = this.state;

    return (
      <ProjectEnvGrid
        axis="xy"
        helperClass={gridItemStyles.dragging}
        isDragging={isDragging}
        onSortEnd={this.onEnvDrop}
        onSortStart={this.onEnvDrag}
        project={project}
        environments={environments}
        subscribeToMore={subscribeToMore}
        useDragHandle
      />
    );
  }
}

export default withRouter(
  graphql(UPDATE_ENV_POSITION, { name: 'updateEnvironmentPosition' })(
    ProjectEnvGridContainer
  )
);
