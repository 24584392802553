import React, { Component } from 'react';
import { graphql } from '@apollo/react-hoc';
import _ from 'lodash';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import swal from '@sweetalert/with-react';

import style from './style.module.css';

export class DeleteButton extends Component {
  static propTypes = {
    // eslint-disable-next-line react/forbid-prop-types, react/no-unused-prop-types
    variables: PropTypes.object.isRequired,
    // eslint-disable-next-line react/forbid-prop-types, react/no-unused-prop-types
    refetchQueries: PropTypes.arrayOf(PropTypes.object),
    onSuccess: PropTypes.func,
    onError: PropTypes.func,
    mutate: PropTypes.func.isRequired,
    confirm: PropTypes.bool,
    className: PropTypes.string,
    buttonIcon: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types, react/no-unused-prop-types
    buttonProps: PropTypes.object
  };

  static defaultProps = {
    confirm: true,
    onSuccess: () => {
      // do nothing
    },
    onError: () => {
      // do nothing
    },
    refetchQueries: [],
    className: '',
    buttonIcon: 'delete',
    buttonProps: {}
  };

  runMutation = () => {
    const { onSuccess, onError, mutate } = this.props;
    const opts = _.pick(this.props, ['variables', 'refetchQueries']);

    mutate(opts)
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(error => {
        onError(error);
      });
  };

  onClick = e => {
    const { confirm } = this.props;

    e.preventDefault();

    if (confirm) {
      swal({
        icon: 'error',
        title: 'Are you sure?',
        dangerMode: true,
        buttons: true
      }).then(confirmed => {
        if (confirmed) {
          this.runMutation();
        }
      });
    } else {
      this.runMutation();
    }
  };

  render() {
    const { buttonIcon, className, buttonProps } = this.props;

    return (
      <button
        type="button"
        className={classnames(style.button, className)}
        onClick={this.onClick}
        {...buttonProps}
      >
        <i className="material-icons">{buttonIcon}</i>
      </button>
    );
  }
}

export function makeButton(query) {
  return graphql(query)(DeleteButton);
}
