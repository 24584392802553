import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Nav from '@creditcards/ui-kit-react/lib/components/Internal/Nav';
import classnames from 'classnames';
import UserDropdown from 'components/UserDropdown';
import { withAuth } from '@creditcards/auth0-react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import logo from 'assets/logo.svg';
import style from './style.module.css';
import ReleaseNotesIndicator from '../ReleaseNotesIndicator';
import Tooltip from '../Tooltip';

class Header extends Component {
  static propTypes = {
    history: PropTypes.shape({
      location: PropTypes.shape({
        pathname: PropTypes.string
      })
    }).isRequired,
    auth: PropTypes.shape({
      hasRole: PropTypes.func,
      logout: PropTypes.func,
      role: PropTypes.array,
      tenant: PropTypes.string,
      profile: PropTypes.shape({
        username: PropTypes.string
      }).isRequired,
      token: PropTypes.shape({
        accessToken: PropTypes.string
      })
    }).isRequired
  };

  state = {
    renderError: false
  };

  renderDevTools = () => {
    const { auth } = this.props;
    const { renderError } = this.state;

    const error = () => {
      throw new Error('Test Render Error');
    };

    return (
      <span className={style.devTools}>
        <i
          onClick={() => navigator.clipboard.writeText(auth.token.accessToken)}
          className="material-icons"
          data-tip="Copy JWT"
        >
          file_copy
        </i>
        <i
          onClick={() => this.setState({ renderError: true })}
          className="material-icons"
          data-tip="Trigger Render Error"
        >
          error
        </i>
        {renderError ? error() : null}
      </span>
    );
  };

  renderRightSide = () => {
    const {
      auth: { logout, profile }
    } = this.props;

    return (
      <div className={style.rightSide}>
        {process.env.NODE_ENV === 'development' ? this.renderDevTools() : null}
        <ReleaseNotesIndicator />
        <UserDropdown user={profile} logout={logout} />
      </div>
    );
  };

  render() {
    const {
      auth: { hasRole, tenant, profile },
      history
    } = this.props;

    const routes = [];
    const superAdmin = get(profile, 'superAdmin', false);

    if (tenant) {
      routes.push({ label: 'Projects', path: `/${tenant}/projects` });

      if (hasRole('ADMIN')) {
        routes.push({ label: 'Admin', path: `/${tenant}/admin` });
      }
    } else {
      routes.push({ label: 'Select Tenant', path: `/select-tenant` });
    }

    if (superAdmin) {
      routes.push({ label: 'Super Admin', path: `/super-admin` });
    }

    const rightRoutes = [
      {
        label: 'Docs',
        path: 'https://shield.rvdocs.io/docs/getting-started/intro.html',
        template: route => (
          <a target="_blank" rel="noopener noreferrer" href={route.path}>
            {route.label}
          </a>
        )
      }
    ];

    if (process.env.NODE_ENV === 'development') {
      rightRoutes.push({ label: 'GraphiQL', path: '/gql' });
    }

    return (
      <React.Fragment>
        <Nav
          theme={{
            navWrap: style.navWrap
          }}
          template={route => <Link to={route.path}>{route.label}</Link>}
          activePath={history.location.pathname}
          logo={
            <Link to="/" className={style.logoLink}>
              <img
                className={classnames(style.logoIcon)}
                src={logo}
                alt="logo"
                data-cy="logo"
              />
            </Link>
          }
          routes={routes}
          rightRoutes={rightRoutes}
          rightSide={this.renderRightSide()}
          underhang
          fluid={false}
        />
        <Tooltip />
      </React.Fragment>
    );
  }
}

export default withRouter(withAuth(Header));
