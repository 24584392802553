import React, { Component } from 'react';
import Button from '@creditcards/ui-kit-react/lib/components/Button';
import { Form , Validators } from '@creditcards/react-forms/lib';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import TextInput from 'components/Form/TextInput';
import extractFieldError from 'utils/gqlErrors';
import SelectInput from 'components/Form/SelectInput';
import { GET_AWS_PROFILES } from 'containers/Admin/queries';
import { QueryLoading } from 'components/LoadingPage';
import { RadioGroup, RadioButton } from '@creditcards/ui-kit-react/lib/components/Radio';

import style from './style.module.css';
import DeploymentStatusOptions from '../DeploymentStatusOptions';
import LambdaUrl from './LambdaUrl'
import getWebHookType from './util'

class WebHookForm extends Component {
  form = React.createRef();

  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func
    }).isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    webHook: PropTypes.shape({
      url: PropTypes.string,
      on: PropTypes.array,
      awsProfile: PropTypes.shape({
        id: PropTypes.string
      })
    }),
    edit: PropTypes.bool,
    gqlError: PropTypes.shape({
      graphQLErrors: PropTypes.array
    })

  };

  static defaultProps = {
    edit: false,
    webHook: {
      on: []
    },
    gqlError: null
  };

  constructor(props) {
    super(props)
    this.state = {
      type: getWebHookType(props?.webHook?.url)
    }

  }

  getError = field => {
    const { gqlError } = this.props;
    return extractFieldError(gqlError, field);
  };

  render() {
    const { onSubmit, onCancel, webHook, edit } = this.props;
    const { type: typeValue } = this.state;
    return (
      <Form
        className={style.form}
        onValidSubmit={onSubmit}
        ref={el => {
          this.form = el;
        }}
      >
        {!edit && (
          <fieldset className={style.radioFieldset}>
            <legend>WebHook Type *</legend>

            <RadioGroup
              label="WebHook Type"
              name="type"
              value={typeValue}
              required
              className={style.radios}
              onChange={(value) => this.setState({ type: value })}
            >
              <RadioButton label="HTTPS" value="https" />
              <RadioButton label="Lambda" value="lambda" />
            </RadioGroup>
          </fieldset>
        )}


        {typeValue === "https" && (
          <TextInput
            name="url"
            type="text"
            placeholder="URL"
            className={style.input}
            defaultValue={get(webHook, 'url')}
            error={this.getError('url')}
            required
          />
        )}

        {typeValue === "lambda" && (
          <>
            <QueryLoading query={GET_AWS_PROFILES} fetchPolicy="cache-first">
              {({ data }) => {
                const awsProfiles = get(data, 'awsProfiles', []);

                return (
                  <SelectInput
                    name="awsProfileId"
                    label="AWS Profile"
                    defaultValue={get(webHook, 'awsProfile.id')}
                    required
                    source={awsProfiles.map(profile => ({
                      value: profile.id,
                      label: profile.name
                    }))}
                    error={this.getError('awsProfile.id')}
                    validation={!Validators.isEmpty}
                  />
                );
              }}
            </QueryLoading>
            <LambdaUrl
              name="url"
              defaultValue={get(webHook, 'url')}
            />
          </>
        )}

        <DeploymentStatusOptions selected={webHook.on} />
        <div className={style.formActions}>
          <Button
            raised
            small
            appearance="primary"
            onClick={e => {
              e.preventDefault();
              this.form.submit();
            }}
          >
            {edit ? 'Update' : 'Create'}
          </Button>

          <Button
            flat
            small
            appearance="danger"
            onClick={e => {
              e.preventDefault();
              onCancel();
            }}
          >
            Cancel
          </Button>
        </div>
      </Form>
    );
  }
}

export default WebHookForm;
