/*  eslint-disable react/prop-types */
import React from 'react';
import { DagreEngine } from '@projectstorm/react-diagrams';
import { CanvasWidget } from '@projectstorm/react-canvas-core';
import style from './style.module.css';

/**
 * Dagre accepts a model and engine (react-diagrams objects) and renders a
 * diagram with automatic positioning, evenly spacing the boxes.
 */
export default class Dagre extends React.Component {
  constructor(props) {
    super(props);
    this.engine = new DagreEngine({
      graph: {
        rankdir: 'TB',
        ranker: 'longest-path',
        marginx: 25,
        marginy: 25
      },
      includeLinks: false
    });
  }

  componentDidMount() {
    const { engine } = this.props;

    setTimeout(() => {
      this.autoDistribute();
      engine.zoomToFit();
    }, 50);
  }

  autoDistribute = () => {
    const { model, engine } = this.props;
    this.engine.redistribute(model);

    engine.repaintCanvas();
  };

  render() {
    const { engine } = this.props;

    return (
      <div className={style.container}>
        <CanvasWidget engine={engine} />
      </div>
    );
  }
}
