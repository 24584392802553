import React, { Component } from 'react';
import Container from 'components/Container';
import EnvForm from 'components/EnvForm';
import Title from 'components/Title';
import {
  CREATE_ENV,
  CREATE_BASIC_LAMBDA_CONFIGURATION,
  CREATE_ECS_CONFIGURATION,
  CREATE_S3_CONFIGURATION,
  CREATE_ECS_SCHEDULED_TASK_CONFIGURATION,
  CREATE_CODEBUILD_CONFIGURATION
} from 'containers/Environments/queries';
import { GET_PROJECT } from 'containers/Projects/queries';
import PropTypes from 'prop-types';
import { QueryLoading } from 'components/LoadingPage';
import {
  Breadcrumbs,
  Crumb,
  Alert,
  CardHead,
  CardContent
} from '@creditcards/ui-kit-react';
import { Link } from 'react-router-dom';
import { graphql } from '@apollo/react-hoc';
import { flowRight } from 'lodash';
import { NotFoundError } from '../../../errors';

class EnvironmentsAdd extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string.isRequired
      })
    }).isRequired,
    createEnv: PropTypes.func.isRequired,
    createBasicLambdaConfig: PropTypes.func.isRequired,
    createEcsConfig: PropTypes.func.isRequired,
    createS3Config: PropTypes.func.isRequired,
    createEcsScheduledTaskConfig: PropTypes.func.isRequired,
    createCodebuildConfig: PropTypes.func.isRequired
  };

  state = {
    error: '',
    providerError: false,
    newEnv: null
  };

  createProvider = async ({ providerConfiguration, newEnv }) => {
    try {
      const {
        createBasicLambdaConfig,
        createEcsConfig,
        createS3Config,
        createEcsScheduledTaskConfig,
        createCodebuildConfig
      } = this.props;

      const {
        data: {
          createEnvironment: { id }
        }
      } = newEnv;

      const { __typename, ...configuration } = providerConfiguration;

      switch (__typename) {
        case 'basic_lambda':
          return await createBasicLambdaConfig({
            variables: {
              environmentId: id,
              configuration
            }
          });

        case 'ecs':
          return await createEcsConfig({
            variables: {
              environmentId: id,
              configuration
            }
          });
        case 's3':
          return await createS3Config({
            variables: {
              environmentId: id,
              configuration
            }
          });
        case 'ecs_scheduled_task':
          return await createEcsScheduledTaskConfig({
            variables: {
              environmentId: id,
              configuration
            }
          });
        case 'codebuild':
          return await createCodebuildConfig({
            variables: {
              environmentId: id,
              configuration
            }
          });
        default:
          return newEnv;
      }
    } catch (error) {
      this.setState({ providerError: true, newEnv });
      throw error;
    }
  };

  createEnv = ({ privileged, autoPromote, tagPattern, ...rest }) => {
    const {
      match: {
        params: { id: projectId }
      },
      createEnv
    } = this.props;

    return createEnv({
      variables: {
        environment: {
          ...rest,
          privileged: privileged === 'true' || privileged === true,
          autoPromote: autoPromote === 'true' || autoPromote === true,
          projectId,
          tagPattern: tagPattern || null
        }
      },
      refetchQueries: [{ query: GET_PROJECT, variables: { id: projectId } }]
    });
  };

  onSubmit = async ({ providerConfiguration, ...rest }) => {
    const {
      history,
      match: {
        params: { id: projectId }
      }
    } = this.props;
    const { providerError } = this.state;
    let { newEnv } = this.state;

    try {
      if (!providerError) {
        newEnv = await this.createEnv({ ...rest });
      }

      if (providerConfiguration) {
        await this.createProvider({ providerConfiguration, newEnv });
      }

      history.push(`../../../projects/${projectId}`);
    } catch (error) {
      this.setState({ error });
    }
  };

  render() {
    const {
      match: { params }
    } = this.props;

    const { error, providerError } = this.state;
    const projectId = params.id;

    return (
      <QueryLoading query={GET_PROJECT} variables={{ id: projectId }}>
        {({ data }) => {
          const { project } = data;

          if (!project) throw new NotFoundError();

          return (
            <Container>
              <Title title={`${project.name} - Add Environment`} />

              <CardHead>
                <Breadcrumbs>
                  <Crumb>
                    <Link to="../../../projects">Projects</Link>
                  </Crumb>

                  <Crumb>
                    <Link to={`../../../projects/${project.id}`}>
                      {project.name}
                    </Link>
                  </Crumb>

                  <Crumb active>New Environment</Crumb>
                </Breadcrumbs>
              </CardHead>

              <CardContent>
                <h1>New Environment</h1>
                <div data-cy="new-environment-form">
                  {error && !providerError ? (
                    <Alert type="danger" message={error.message} />
                  ) : null}

                  {providerError ? (
                    <Alert
                      type="warning"
                      message='The environment was successfully created, however, there was an error setting up the deployment configuration.
                      You can find the errors in the "Setup Deployment Configuration" section below.
                      You may attempt to fix the problems and try again, or setup the configuration at a later time.'
                    />
                  ) : null}
                  <EnvForm
                    providerConfigError={providerError}
                    gqlError={error}
                    projectId={projectId}
                    onSubmit={this.onSubmit}
                  />
                </div>
              </CardContent>
            </Container>
          );
        }}
      </QueryLoading>
    );
  }
}

export default flowRight(
  graphql(CREATE_ENV, { name: 'createEnv' }),
  graphql(CREATE_BASIC_LAMBDA_CONFIGURATION, {
    name: 'createBasicLambdaConfig'
  }),
  graphql(CREATE_ECS_CONFIGURATION, { name: 'createEcsConfig' }),
  graphql(CREATE_S3_CONFIGURATION, { name: 'createS3Config' }),
  graphql(CREATE_ECS_SCHEDULED_TASK_CONFIGURATION, {
    name: 'createEcsScheduledTaskConfig'
  }),
  graphql(CREATE_CODEBUILD_CONFIGURATION, {
    name: 'createCodebuildConfig'
  })
)(EnvironmentsAdd);
