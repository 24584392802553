import React, { Component } from 'react';
import { MultiSelect } from '@creditcards/ui-kit-react';
import { graphql } from '@apollo/react-hoc';
import PropTypes from 'prop-types';
import { LIST_PROJECTS } from '../../containers/Projects/queries';

class ProjectSelector extends Component {
  static propTypes = {
    data: PropTypes.shape({
      loading: PropTypes.bool,
      projects: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string
        })
      )
    }).isRequired,
    filter: PropTypes.func
  };

  static defaultProps = {
    filter: () => true
  };

  render() {
    const {
      data: { projects, loading },
      filter,
      ...rest
    } = this.props;

    if (loading) return '...';

    return (
      <MultiSelect
        options={projects
          .filter(filter)
          .map(p => ({ label: p.name, value: p.id }))}
        {...rest}
      />
    );
  }
}

export default graphql(LIST_PROJECTS)(ProjectSelector);
