import React, { FC } from 'react';
import Alert from '@creditcards/ui-kit-react/lib/components/Alert';
import { GET_ENV } from 'containers/Environments/queries';
import { useCreateSlackReminderMutation } from 'generated/graphql';
import SlackReminderForm from './SlackReminderForm';

import style from '../SlackAlertInlineAdd/style.module.css';

interface Props {
  onClose: () => void;
  environmentId: string;
}

const SlackAlertInlineAdd: FC<Props> = ({ environmentId, onClose }) => {
  const [create, { error, loading }] = useCreateSlackReminderMutation({
    onCompleted: onClose,
    refetchQueries: [{ query: GET_ENV, variables: { id: environmentId } }],
  });

  return (
    <div className={style.container} data-cy="slack-notification-form">
      {error ? <Alert type="danger" message={error.message} /> : null}

      <div className={style.heading}>New Reminder</div>

      <SlackReminderForm
        error={error}
        loading={loading}
        onCancel={onClose}
        onSubmit={(form) =>
          create({
            variables: {
              input: {
                environmentId,
                channel: form.channel,
              },
            },
          })
        }
      />
    </div>
  );
};

export default SlackAlertInlineAdd;
