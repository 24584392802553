import { Component, ReactNode } from 'react';
import { withAuth } from '@creditcards/auth0-react';

interface ProtectedProps {
  auth: {
    hasRole: (role: string) => boolean;
  };
  children: ReactNode;
  hasRole: string;
}

class Protected extends Component<ProtectedProps> {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    children: null
  };

  render(): ReactNode {
    const { auth, hasRole, children } = this.props;

    return auth.hasRole(hasRole) ? children : null;
  }
}

export default withAuth(Protected);
