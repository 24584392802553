import React, { PureComponent } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { AuthContext } from '@creditcards/auth0-react';
import { withAppContext } from 'context';
import { QueryLoading } from '../LoadingPage';
import { GET_PROFILE } from '../../containers/App/queries';

class ContextComposer extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    tenant: PropTypes.string.isRequired,
    setTenant: PropTypes.func.isRequired
  };

  render() {
    const { children, tenant, setTenant } = this.props;

    return (
      <QueryLoading query={GET_PROFILE}>
        {/* This is how we add app-specific features to the context provided from the <Auth0 /> component */}
        {({ data }) => (
          <AuthContext.Consumer>
            {context => {
              if (!tenant && data?.profile?.currentTenant) {
                setTenant(data?.profile?.currentTenant);
              }

              const mergedContext = {
                ...context,
                profile: get(data, 'profile', {}),
                hasRole: role => get(data, 'profile.roles', []).includes(role),
                tenant
              };

              return (
                <AuthContext.Provider value={mergedContext}>
                  {children}
                </AuthContext.Provider>
              );
            }}
          </AuthContext.Consumer>
        )}
      </QueryLoading>
    );
  }
}

export default withAppContext(ContextComposer);
