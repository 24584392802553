import React from 'react';
import ReactTooltip, { TooltipProps as ReactTooltipProps } from 'react-tooltip';

import style from './style.module.css';

interface TooltipProps extends ReactTooltipProps {}

const Tooltip: React.FC<TooltipProps> = ({
  effect = 'solid',
  multiline = true,
  place = 'top',
  ...rest
}) => (
  <ReactTooltip
    className={style.tooltip}
    effect={effect}
    multiline={multiline}
    place={place}
    {...rest}
  />
);

export default Tooltip;
