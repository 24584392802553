import React, { Component } from 'react';
import hoistNonReactStatic from 'hoist-non-react-statics';
import qs from 'query-string';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

class RoutedPagination extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func
    }).isRequired
  };

  state = {
    page: 1
  };

  /**
   * When location.search changes (back/forward buttons), the state is updated
   * with the new page -- if there is one
   */
  static getDerivedStateFromProps(props) {
    const {
      location: { search }
    } = props;

    const { page } = qs.parse(search);

    return { page: page || 1 };
  }

  componentDidMount = () => {
    const {
      location: { search }
    } = this.props;

    const { page } = qs.parse(search);

    if (page) this.setState({ page });
  };

  changePage = newPage => {
    const {
      history: { push },
      location: { search, pathname }
    } = this.props;

    const query = qs.parse(search);

    if (newPage > 1 && (!query.page || query.page !== newPage)) {
      query.page = newPage;
    } else {
      delete query.page;
    }

    push(`${pathname}?${qs.stringify(query)}`);

    this.setState({ page: newPage });
  };

  render() {
    const { children } = this.props;
    const { page } = this.state;

    return React.cloneElement(children, {
      page: Number.parseInt(page, 10),
      changePage: this.changePage
    });
  }
}

const RoutedPaginationWithRouter = withRouter(RoutedPagination);

export default RoutedPaginationWithRouter;

export const withRoutedPagination = BaseComponent => {
  const wrapped = props => (
    <RoutedPaginationWithRouter>
      <BaseComponent {...props} />
    </RoutedPaginationWithRouter>
  );

  wrapped.displayName = `withRoutedPagination(${BaseComponent.displayName ||
    BaseComponent.name})`;
  wrapped.WrappedComponent = BaseComponent;

  return hoistNonReactStatic(wrapped, BaseComponent);
};
