import React, { Component } from 'react';
import config from 'config';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Auth0, { AuthContext } from '@creditcards/auth0-react';
import cypressCheck from 'utils/testing';
import { withAppContext } from 'context';

class Auth extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    setToken: PropTypes.func.isRequired,
    history: PropTypes.shape({
      location: PropTypes.shape({
        origin: PropTypes.string,
        pathname: PropTypes.string
      }),
      replace: PropTypes.func
    }).isRequired
  };

  componentDidMount = () => {
    if (cypressCheck('simulate onAuthed')) {
      const { onAuthed } = this.props;

      onAuthed({ accessToken: process.env.REACT_APP_AUTH_TOKEN });
    }
  };

  /**
   * Drops an AuthContext similar to what <Auth0 /> would provide
   */
  cypressShim = () => {
    const { children } = this.props;
    const token = process.env.REACT_APP_AUTH_TOKEN;

    return (
      <AuthContext.Provider
        value={{
          // eslint-disable-next-line no-alert
          logout: () => alert('logout'),
          token: { accessToken: token }
        }}
      >
        {children}
      </AuthContext.Provider>
    );
  };

  render() {
    const {
      history: { location },
      setToken,
      children
    } = this.props;

    if (cypressCheck('shim auth')) return this.cypressShim();

    return (
      <Auth0
        config={{
          domain: config.AUTH.DOMAIN,
          clientID: config.AUTH.CLIENT_ID,
          redirectUri: `${window.location.origin}/auth?to=${location.pathname}`,
          responseType: 'token id_token',
          scope: 'openid profile email',
          audience: config.AUTH.AUDIENCE
        }}
        onAuthed={setToken}
      >
        {() => children}
      </Auth0>
    );
  }
}

export default withAppContext(withRouter(Auth));
export { default as Redirector } from './Redirector';
export { default as ContextComposer } from './ContextComposer';
