/* eslint-disable max-classes-per-file */
import { ApolloError } from 'apollo-client';

export class NotFoundError extends Error {
  name = 'NotFoundError';

  title = '404';

  icon = 'search';

  constructor(
    message: string = "Sorry, we didn't find what you're looking for."
  ) {
    super(message);
  }
}

export class UnauthorizedError extends Error {
  title = 'Unauthorized';

  icon = 'error_outline';
}

export class GraphQLError extends Error {
  title = 'GraphQL Error';

  icon = 'error_outline';

  constructor(apolloError: ApolloError) {
    super();
    Object.assign(this, apolloError);
  }
}
