/* eslint-disable react/jsx-one-expression-per-line */

import React from 'react';

import { Alert, CardContent, CardHead } from '@creditcards/ui-kit-react';
import { withAuth } from '@creditcards/auth0-react';
import PropTypes from 'prop-types';
import { orderBy } from 'lodash';
import Tooltip from 'components/Tooltip';
import Title from 'components/Title';
import AccessAdminCard from 'components/AccessAdminCard';
import slack from 'assets/Slack_Mark.svg';
import style from './style.module.css';
import Container from '../../../components/Container';
import getUserRole from '../../../utils/getUserRole';
import { useGetUserRolesQuery } from '../../../generated/graphql';

const Access = ({ auth: { profile, tenant } }) => {
  const { data, loading, error } = useGetUserRolesQuery();

  if (error) return 'error'; // waiting for error page PR

  if (loading) return null;

  const admins = orderBy(
    data.userRoles.filter(user => user.roles.includes('ADMIN')),
    ({ user }) => [user.updatedAt, user.displayName],
    ['desc', 'asc']
  );

  const tenantName = tenant.toUpperCase();

  return (
    <Container>
      <Title title="Access" />
      <CardHead>{tenantName} Admins</CardHead>
      <CardContent>
        <Alert
          type="info"
          message={
            <React.Fragment>
              <div className={style.message}>
                Your current access level for this tenant is
                <strong> {getUserRole(profile.roles)}</strong>.
              </div>
              <div>
                If you need elevated access, you can reach out to one of the
                admins below.
              </div>
            </React.Fragment>
          }
        />

        <div className={style.admins}>
          {admins.map(({ user }) => (
            <AccessAdminCard key={user.id} user={user} />
          ))}
          {admins.length === 0 && (
            <div>
              There are no admins for this tenant. Contact the team via the
              Slack channel{' '}
              <a
                href="slack://channel?team=T029AV3QX&id=CG25XE8P5"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img
                  alt="Send a Slack Message"
                  className={style.slackImage}
                  src={slack}
                />
                #shield-team-pub
              </a>{' '}
              to get access.
            </div>
          )}
        </div>
      </CardContent>
      <Tooltip />
    </Container>
  );
};

Access.propTypes = {
  auth: PropTypes.shape({
    profile: PropTypes.shape({
      roles: PropTypes.arrayOf(
        PropTypes.oneOf(['ADMIN', 'DEPLOYER', 'USER']).isRequired
      ).isRequired
    }).isRequired,
    tenant: PropTypes.string
  }).isRequired
};

export default withAuth(Access);
