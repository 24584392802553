import gql from 'graphql-tag';

export const GET_PROFILE = gql`
  query {
    profile {
      currentTenant
      displayName
      id
      roles
      slackId
      username
      email
      superAdmin
    }
  }
`;

export const GET_TENANTS = gql`
  query {
    tenants
  }
`;

export const ASSOCIATE_SLACK = gql`
  mutation($token: String!) {
    associateSlackAccount(token: $token) {
      id
    }
  }
`;
