import gql from 'graphql-tag';

export const SEARCH_PROJECTS = gql`
  query($page: Int, $search: String) {
    searchProjects(search: $search, page: $page, pageSize: 15) {
      pagination {
        pageSize
        pageNumber
        totalEntries
      }

      entries {
        id
        name
        repository

        badges {
          url
          imgSrc
        }

        environments {
          name
          id
        }

        pulse {
          value
        }
      }
    }
  }
`;

export const CREATE_PROJECT = gql`
  mutation($project: CreateProjectInput!) {
    createProject(input: $project) {
      name
      id
      description
    }
  }
`;

export const GET_PROJECT = gql`
  query($id: ID!) {
    project(id: $id) {
      id
      name
      slug
      repository
      description

      environments {
        id
        name
        privileged
        slug
        url
        newRelicAppId
        tagPattern
        position

        providerConfiguration {
          __typename

          ... on BasicLambdaConfiguration {
            id
          }

          ... on EcsConfiguration {
            id
          }

          ... on S3Configuration {
            id
          }

          ... on EcsScheduledTaskConfiguration {
            id
          }

          ... on CodebuildConfiguration {
            id
          }
        }

        lastDeployment {
          id
          status
          ref
          insertedAt
          artifactName
          autoPromoted

          deployer {
            id
            displayName
            username
          }
        }

        newRelicAccount {
          id
          accountId
        }
      }

      archivedEnvironments: environments(onlyArchived: true) {
        id
      }

      references {
        name
        url
        id
      }

      badges {
        url
        imgSrc
      }
    }
  }
`;

export const GET_PROJECT_FOR_EDIT = gql`
  query($id: ID!) {
    project(id: $id) {
      id
      name
      description
      repository

      badges {
        url
        imgSrc
      }

      environments(includeArchived: true) {
        id
      }
    }
  }
`;

export const GET_PROJECT_FOR_REMOVAL = gql`
  query($id: ID!) {
    project(id: $id) {
      id

      environments(includeArchived: true) {
        id
      }
    }
  }
`;

export const UPDATE_PROJECT = gql`
  mutation($id: ID!, $project: UpdateProjectInput!) {
    updateProject(id: $id, input: $project) {
      name
      id
      description
      badges {
        url
        imgSrc
      }
    }
  }
`;

export const CREATE_REFERENCE = gql`
  mutation($reference: CreateReferenceInput!) {
    createReference(input: $reference) {
      id
      name
      url
    }
  }
`;

export const UPDATE_REFERENCE = gql`
  mutation($id: ID!, $reference: UpdateReferenceInput!) {
    updateReference(id: $id, input: $reference) {
      id
      name
      url
    }
  }
`;

export const DELETE_REFERENCE = gql`
  mutation($id: ID!) {
    deleteReference(id: $id)
  }
`;

export const ARCHIVE_PROJECT = gql`
  mutation($id: ID!) {
    archiveProject(id: $id) {
      id
    }
  }
`;

export const DELETE_PROJECT = gql`
  mutation($id: ID!) {
    deleteProject(id: $id)
  }
`;

export const LIST_PROJECTS = gql`
  query {
    projects {
      id
      name
    }
  }
`;

export const CREATE_PROJECT_GROUP = gql`
  mutation($input: CreateProjectGroupInput!) {
    createProjectGroup(input: $input) {
      id
      name
      projects {
        id
      }
    }
  }
`;

export const GET_PROJECT_GROUPS = gql`
  query($page: Int, $pageSize: Int) {
    projectGroups(page: $page, pageSize: $pageSize, onlyRoots: true) {
      pagination {
        pageNumber
        totalPages
      }
      entries {
        id
        name
        projects {
          id
        }
      }
    }
  }
`;
