export default action => {
  const hasCypress = !!window.Cypress;

  if (hasCypress) {
    // eslint-disable-next-line no-console
    console.warn(`Cypress Check: ${action} while running in Cypress`);
  }

  return hasCypress;
};
