import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { formField } from '@creditcards/react-forms';
import Dropdown from '@creditcards/ui-kit-react/lib/components/Dropdown';

class SelectInput extends Component {
  static propTypes = {
    validationErrors: PropTypes.arrayOf(PropTypes.string).isRequired,
    onChange: PropTypes.func,
    error: PropTypes.string,
    setValue: PropTypes.func.isRequired,
    standAlone: PropTypes.bool.isRequired,
    isValid: PropTypes.bool.isRequired,
    isPristine: PropTypes.bool.isRequired
  };

  static defaultProps = {
    error: '',
    onChange: () => {
      // do nothing
    }
  };

  composeErrors = () => {
    const { validationErrors, error } = this.props;
    return error
      ? [...validationErrors, error].join()
      : validationErrors.join();
  };

  render() {
    const {
      setValue,
      isValid,
      onChange,
      error,
      standAlone,
      validationErrors,
      isPristine,
      ...rest
    } = this.props;

    return (
      <Dropdown
        onChange={value => {
          setValue(value);
          if (onChange) onChange(value);
        }}
        error={!isPristine && !isValid ? this.composeErrors() : ''}
        {...rest}
      />
    );
  }
}

export default formField(SelectInput);
