import React, { createContext, useState, useContext, FC } from 'react';

interface AppContext {
  token: string;
  tenant: string;
}

interface Token {
  accessToken: string;
}

const Context = createContext<AppContext>({
  token: '',
  tenant: ''
});

export const ContextProvider: FC = ({ children }) => {
  const [token, setToken] = useState('');
  const [tenant, setTenant] = useState('');

  const updateToken = (newToken: Token): void => setToken(newToken.accessToken);
  const context = { token, setToken: updateToken, tenant, setTenant };

  return <Context.Provider value={context}>{children}</Context.Provider>;
};

export function useAppContext(): AppContext {
  const context = useContext(Context);
  return context;
}

export function withAppContext(Component: React.ComponentType): FC<any> {
  return props => (
    <Context.Consumer>
      {ctx => <Component {...props} {...ctx} />}
    </Context.Consumer>
  );
}
