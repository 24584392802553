import React, { FC } from 'react';
import Tooltip from 'components/Tooltip';
import style from './style.module.css';

interface Props {
  artifactName: string;
}

const ArtifactName: FC<Props> = ({ artifactName }) => {
  const name = artifactName || 'Unknown';

  return (
    <>
      <span className={style.text} data-tip={name}>
        {name.length >= 40 ? name.slice(0, 8) : name}
      </span>
      <Tooltip />
    </>
  );
};

export default ArtifactName;
