import React, { Component } from 'react';
import { Button } from '@creditcards/ui-kit-react';
import PropTypes from 'prop-types';
import AddProject from '../AddProjectToGroup';
import { GET_PROJECT_GROUP } from '../../../containers/ProjectGroups/queries';

class AppProjectToGroupButton extends Component {
  static propTypes = {
    projectGroup: PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string
    }).isRequired
  };

  state = {
    showAddProject: false
  };

  render() {
    const { projectGroup, ...rest } = this.props;
    const { showAddProject } = this.state;

    return (
      <React.Fragment>
        <Button
          flat
          appearance="primary"
          icon="add"
          onClick={() => this.setState({ showAddProject: true })}
          {...rest}
        >
          Add Project
        </Button>
        <AddProject
          active={showAddProject}
          projectGroup={projectGroup}
          onClose={() => this.setState({ showAddProject: false })}
          refetchQueries={[
            {
              query: GET_PROJECT_GROUP,
              variables: { id: projectGroup.id }
            }
          ]}
        />
      </React.Fragment>
    );
  }
}

export default AppProjectToGroupButton;
