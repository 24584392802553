import React, { Component } from 'react';
import { formField } from '@creditcards/react-forms';
import PropTypes from 'prop-types';
import Checkbox from '@creditcards/ui-kit-react/lib/components/Checkbox';
import styles from './style.module.css';

class CheckBox extends Component {
  static propTypes = {
    setValue: PropTypes.func.isRequired,
    standAlone: PropTypes.bool.isRequired,
    isPristine: PropTypes.bool.isRequired,
    isValid: PropTypes.bool.isRequired,
    validationErrors: PropTypes.arrayOf(PropTypes.string).isRequired,
    value: PropTypes.bool.isRequired
  };

  render() {
    const {
      setValue,
      isPristine,
      isValid,
      standAlone,
      validationErrors,
      value,
      ...rest
    } = this.props;

    return (
      <Checkbox
        theme={{ text: styles.text }}
        onChange={_value => setValue(_value)}
        {...rest}
        checked={value}
      />
    );
  }
}

export default formField(CheckBox);
