import React, { Component } from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { graphql } from '@apollo/react-hoc';
import PropTypes from 'prop-types';
import { withAppContext } from 'context';
import Projects from '../Projects';
import ProjectGroups from '../ProjectGroups';
import Environments from '../Environments';
import Access from '../../pages/Tenant/Access';
import { GET_PROFILE, GET_TENANTS } from '../App/queries';
import { SET_CURRENT_TENANT } from './queries';
import { QueryLoading } from '../../components/LoadingPage';

const Admin = React.lazy(() => import('../Admin'));

class Tenant extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.object,
      path: PropTypes.string,
      url: PropTypes.string
    }).isRequired,
    history: PropTypes.shape({
      replace: PropTypes.func
    }).isRequired,
    setTenant: PropTypes.func.isRequired,
    tenant: PropTypes.string.isRequired,
    setCurrentTenant: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.checkTenant();
  }

  componentDidUpdate() {
    this.checkTenant();
  }

  checkTenant = () => {
    const {
      match: {
        params: { tenant }
      },
      setTenant,
      tenant: currentTenant
    } = this.props;

    if (tenant && tenant !== currentTenant) {
      setTenant(tenant);
    }
  };

  render() {
    const { match, history, setCurrentTenant } = this.props;

    const {
      path,
      url,
      params: { tenant }
    } = match;

    return (
      <QueryLoading
        query={GET_TENANTS}
        onCompleted={({ tenants }) => {
          if (tenant) {
            if (tenants.includes(tenant)) {
              setCurrentTenant({
                variables: { tenant },
                refetchQueries: [{ query: GET_PROFILE }]
              });
            } else {
              history.replace('/select-tenant', { tenant });
            }
          }
        }}
      >
        {() => (
          <Switch>
            <Redirect exact from={url} to={`${url}/projects`} />
            <Route path={`${path}/projects`} component={Projects} />
            <Route path={`${path}/project-groups`} component={ProjectGroups} />
            <Route path={`${path}/environments`} component={Environments} />
            <Route path={`${path}/admin`} component={Admin} />
            <Route path={`${path}/access`} component={Access} />
          </Switch>
        )}
      </QueryLoading>
    );
  }
}

export default withAppContext(
  withRouter(graphql(SET_CURRENT_TENANT, { name: 'setCurrentTenant' })(Tenant))
);
export { default as Redirector } from './Redirector';
