import React, { Component } from 'react';
import {
  formField,
  FormFieldInjectedProps,
  ValidationErrors
} from '@creditcards/react-forms';
// eslint-disable-next-line import/no-named-default
import { default as _Select, Styles, Props, ValueType } from 'react-select';
import classnames from 'classnames';
import style from './style.module.css';

export interface Option {
  label: string;
  value: string;
}

export interface SelectProps extends Props<Option> {
  name: string;
  label?: string;
  className?: string;
  isMulti?: boolean;
  placeholder?: string;
  autoFocus?: boolean;
  onValueChange?: (value: string | any[] | null) => void;
}

const getFieldErrors = (
  validationErrors: ValidationErrors,
  isPristine: boolean
): string | boolean => {
  if (isPristine) return false;

  return validationErrors.join(', ');
};

function notUndefined<T>(x: T | undefined): x is T {
  return x !== undefined;
}

// NOTE: React-Select has a significantly different way of styling things,
// requiring us to do this instead of the typical css modules approach
const styleOpts: Partial<Styles> = {
  control: (provided) => ({
    ...provided,
    color: 'var(--color-text)',
    backgroundColor: 'var(--input-text-background-color)',
    border: 'var(--input-border)'
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'var(--color-text)'
  }),
  input: (provided) => ({
    ...provided,
    color: 'var(--color-text)'
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: '4px'
  }),
  menuList: (provided) => ({
    ...provided,
    color: 'var(--color-text)',
    backgroundColor: 'var(--input-text-background-color)',
    border: 'var(--input-border)'
  }),
  option: (provided, { isFocused }) => ({
    ...provided,
    backgroundColor: isFocused ?
      'var(--menu-item-background-hover)' :
      provided.backgroundColor,
    cursor: isFocused ? 'pointer' : provided.backgroundColor
  })
}

export class Select extends Component<SelectProps & FormFieldInjectedProps> {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    isMulti: false
  };

  render() {
    const {
      value,
      setValue,
      name,
      label,
      className,
      isPristine,
      validationErrors,
      isMulti,
      options,
      onValueChange,
      ...rest
    } = this.props;

    const error = getFieldErrors(validationErrors, isPristine);

    const getValue = (
      _value: string | string[]
    ): Option | Option[] | undefined => {
      const findOption = (_id: string) => (options as Option[])?.find(a => a.value === _id);

      if (Array.isArray(_value)) {
        return _value.map(findOption).filter(notUndefined);
      }

      return findOption(_value);
    };

    return (
      <div className={classnames(style.select, className)}>
        {label && (
          <label htmlFor={name} className={style.label}>
            {label}
          </label>
        )}
        {/* eslint-disable-next-line react/jsx-pascal-case */}
        <_Select
          options={options}
          styles={styleOpts}
          value={getValue(value)}
          onChange={(selectedOptions: ValueType<Option>) => {
            let selectedValue = null;

            if (selectedOptions) {
              selectedValue = Array.isArray(selectedOptions)
                ? selectedOptions.map(s => s.value)
                : (selectedOptions as Option).value;
            }

            onValueChange?.(selectedValue);
            setValue(selectedValue);
          }}
          isMulti={isMulti}
          {...rest}
        />
        {error && <span className={style.error}>{error}</span>}
      </div>
    );
  }
}

export default formField(Select);
