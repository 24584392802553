import React, { Component } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { awsRegions, humanize } from 'utils/enums';
import { Validators } from '@creditcards/react-forms';
import TextInput from '../../Form/TextInput';
import extractFieldError from '../../../utils/gqlErrors';
import SelectInput from '../../Form/SelectInput';
import { GET_AWS_PROFILES } from '../../../containers/Admin/queries';
import { QueryLoading } from '../../LoadingPage';
import Checkbox from '../../Form/Checkbox';
import HelpTip from '../../HelpTip';

import style from './style.module.css';

const s3Validation = Validators.isURL({
  protocols: ['s3'],
  require_tld: false,
  require_protocol: true,
  allow_underscores: true
});

class S3Fields extends Component {
  static propTypes = {
    s3Configuration: PropTypes.shape({
      image: PropTypes.string,
      service: PropTypes.string
    }),
    gqlError: PropTypes.shape({
      graphQLErrors: PropTypes.array
    })
  };

  static defaultProps = {
    gqlError: null,
    s3Configuration: {}
  };

  getError = field => {
    const { gqlError } = this.props;
    return extractFieldError(gqlError, field);
  };

  render() {
    const { s3Configuration } = this.props;

    return (
      <React.Fragment>
        <TextInput
          name="providerConfiguration[__typename]"
          type="hidden"
          defaultValue="s3"
        />

        <TextInput
          name="providerConfiguration[artifactBucket]"
          label="Artifact Bucket"
          defaultValue={get(s3Configuration, 'artifactBucket')}
          required
          error={this.getError('artifactBucket')}
          placeholder="s3://bucket/path"
          validation={{
            'Invalid S3 bucket URI': s3Validation
          }}
        />

        <TextInput
          name="providerConfiguration[deployBucket]"
          label="Deploy Bucket"
          defaultValue={get(s3Configuration, 'deployBucket')}
          required
          error={this.getError('deployBucket')}
          placeholder="s3://bucket"
          validation={{
            'Invalid S3 bucket URI': s3Validation,
            'URI must be the root bucket': uri =>
              uri.match(/^s3:\/\/[^/]+\/?$/i)
          }}
        />

        <TextInput
          name="providerConfiguration[distributionId]"
          label="CloudFront Distribution ID(s)"
          defaultValue={get(s3Configuration, 'distributionId', null)}
          error={this.getError('distributionId')}
          placeholder="EDFDVBD632BHDS5"
          hint="If provided, this distribution's cache will be cleared after deploy. Multiple distribution IDs can be specified, separated by commas."
          theme={{
            hint: style.hint
          }}
        />

        <SelectInput
          name="providerConfiguration[region]"
          label="Region"
          defaultValue={get(s3Configuration, 'region')}
          required
          error={this.getError('region')}
          source={awsRegions.map(region => ({
            value: region,
            label: humanize(region)
          }))}
        />
        <QueryLoading query={GET_AWS_PROFILES} fetchPolicy="cache-first">
          {({ data }) => {
            const awsProfiles = get(data, 'awsProfiles', []);

            return (
              <SelectInput
                name="providerConfiguration[awsProfileId]"
                label="AWS Profile"
                defaultValue={get(s3Configuration, 'awsProfile.id')}
                required
                source={awsProfiles.map(profile => ({
                  value: profile.id,
                  label: profile.name
                }))}
                error={this.getError('awsProfile.id')}
                validation={!Validators.isEmpty}
              />
            );
          }}
        </QueryLoading>

        <Checkbox
          name="providerConfiguration[deleteOldFiles]"
          label={
            <React.Fragment>
              Delete Old Files &nbsp;
              <HelpTip text="If checked, will delete any files remaining in the S3 bucket not present in the release." />
            </React.Fragment>
          }
          defaultValue={get(s3Configuration, 'deleteOldFiles') ?? false}
          error={this.getError('deleteOldFiles')}
        />
      </React.Fragment>
    );
  }
}

export default S3Fields;
