/* eslint-disable react/jsx-one-expression-per-line */
import React, { Component } from 'react';
import { Container } from '@creditcards/ui-kit-react';
import slack from 'assets/Slack_Mark.svg';
import github from 'assets/GitHub-Mark-Light-32px.png';
import logo from 'assets/logo.svg';
import { sample } from 'lodash';
import style from './style.module.css';

const tips = [
  <span>
    Press <code>p</code> to quickly switch between projects in the current
    tenant.
  </span>,
  <span>
    Press <code>t</code> to quickly switch between tenants.
  </span>
];

class Footer extends Component {
  render() {
    return (
      <div className={style.footerWrap}>
        <div className={style.tip}>{sample(tips)}</div>
        <footer>
          <Container className={style.container}>
            <div>
              <a
                href="https://redventures.slack.com/messages/CG25XE8P5"
                target="_blank"
                rel="noreferrer noopener"
                className={style.logoLink}
              >
                <img alt="Slack Channel" src={slack} />
                #shield-team-pub
              </a>
            </div>
            <div>
              <img className={style.shieldLogo} src={logo} alt="Shield" />
            </div>
            <div>
              <a
                href="https://github.com/CreditCardsCom/shield-ui"
                target="_blank"
                rel="noreferrer noopener"
                className={style.logoLink}
              >
                <img alt="shield-ui" src={github} />
                CreditCardsCom/shield-ui
              </a>
            </div>
          </Container>
        </footer>
      </div>
    );
  }
}

export default Footer;
