import React, { Component } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { awsRegions, humanize } from 'utils/enums';
import { Validators } from '@creditcards/react-forms';
import TextInput from 'components/Form/TextInput';
import extractFieldError from 'utils/gqlErrors';
import SelectInput from 'components/Form/SelectInput';
import { GET_AWS_PROFILES } from 'containers/Admin/queries';
import { QueryLoading } from 'components/LoadingPage';
import RadioGroup, { RadioButton } from 'components/Form/RadioGroup';

import styles from './style.module.css';

const s3Validation = Validators.isURL({
  protocols: ['s3'],
  require_tld: false,
  require_protocol: true,
  allow_underscores: true
});

class BasicLambdaFields extends Component {
  static propTypes = {
    configuration: PropTypes.shape({
      artifactBucket: PropTypes.string,
      functionName: PropTypes.string,
      image: PropTypes.string,
      region: PropTypes.string,
      type: PropTypes.oneOf(['ZIP', 'CONTAINER']),
      awsProfile: PropTypes.shape({
        id: PropTypes.string
      })
    }),
    gqlError: PropTypes.shape({
      graphQLErrors: PropTypes.array
    })
  };

  static defaultProps = {
    gqlError: null,
    configuration: {}
  };

  state = {
    type: null
  };

  getError = field => {
    const { gqlError } = this.props;
    return extractFieldError(gqlError, field);
  };

  render() {
    const { configuration } = this.props;
    const { type } = this.state;

    const typeValue = type || configuration?.type || 'ZIP';

    return (
      <React.Fragment>
        <TextInput
          name="providerConfiguration[__typename]"
          type="hidden"
          defaultValue="basic_lambda"
          error=""
        />

        <fieldset className={styles.radioFieldset}>
          <legend>Lambda Type *</legend>

          <RadioGroup
            label="Lambda Type"
            name="providerConfiguration[type]"
            defaultValue={typeValue}
            required
            className={styles.radios}
            validation={(value) => {
              // this technically doesn't perform validation, but it's the only
              // hook we have into the actual value from the radio group
              if (value && value !== typeValue) this.setState({ type: value });
              return true;
            }}
          >
            <RadioButton label="Zip" value="ZIP" />
            <RadioButton label="Container" value="CONTAINER" />
          </RadioGroup>
        </fieldset>

        {typeValue === 'ZIP' && (
          <TextInput
            name="providerConfiguration[artifactBucket]"
            label="Artifact Bucket"
            defaultValue={get(configuration, 'artifactBucket')}
            required
            error={this.getError('artifactBucket')}
            placeholder="s3://bucket/path"
            validation={{
              'Invalid S3 bucket URI': s3Validation
            }}
          />
        )}

        {typeValue === 'CONTAINER' && (
          <TextInput
            name="providerConfiguration[image]"
            label="Image"
            defaultValue={get(configuration, 'image')}
            required
            error={this.getError('image')}
          />
        )}

        <TextInput
          name="providerConfiguration[functionName]"
          label="Function Name"
          defaultValue={get(configuration, 'functionName')}
          required
          error={this.getError('functionName')}
          placeholder="super-awesome-function"
        />

        <SelectInput
          name="providerConfiguration[region]"
          label="Region"
          defaultValue={get(configuration, 'region')}
          required
          error={this.getError('region')}
          source={awsRegions.map(region => ({
            value: region,
            label: humanize(region)
          }))}
        />
        <QueryLoading query={GET_AWS_PROFILES} fetchPolicy="cache-first">
          {({ data }) => {
            const awsProfiles = get(data, 'awsProfiles', []);

            return (
              <SelectInput
                name="providerConfiguration[awsProfileId]"
                label="AWS Profile"
                defaultValue={get(configuration, 'awsProfile.id')}
                required
                source={awsProfiles.map(profile => ({
                  value: profile.id,
                  label: profile.name
                }))}
                error={this.getError('awsProfile.id')}
                validation={!Validators.isEmpty}
              />
            );
          }}
        </QueryLoading>
      </React.Fragment>
    );
  }
}

export default BasicLambdaFields;
