import React, { FC, useState } from 'react';
import classnames from 'classnames';
import ProjectBadgeForm from 'components/ProjectBadgeForm';
import { uniqueId } from 'lodash';
import Tooltip from 'components/Tooltip';

import { useUpdateProjectMutation } from 'generated/graphql';
import style from './style.module.css';

interface Project {
  id: string;
  name?: string;
  description?: string;
  repository?: string;
  badges: {
    __typename: string;
    url: string;
    imgSrc: string;
  }[];
}

interface AddBadgeButtonProps {
  onClick: () => void;
  project: Project;
}

const AddBadgeButton: FC<AddBadgeButtonProps> = ({ onClick, project }) => (
  <span data-tip="Add badge" data-cy="add-badge-btn" data-for={project.id}>
    <i className={classnames('material-icons', style.addBox)} onClick={onClick}>
      add_box
    </i>
  </span>
);

interface DeleteBadgeButtonProps {
  index: number;
  project: Project;
}

const DeleteBadgeButton: FC<DeleteBadgeButtonProps> = ({ index, project }) => {
  const [updateProject] = useUpdateProjectMutation();

  const deleteBadge = (badgeIndex: number): void => {
    updateProject({
      variables: {
        id: project.id,
        project: {
          name: project.name,
          description: project.description,
          repository: project.repository,
          badges: project.badges
            .filter((b, i) => i !== badgeIndex)
            .map(({ __typename, ...rest }) => rest)
        }
      }
    });
  };

  return (
    <>
      <span
        data-tip="Delete badge"
        data-cy="delete-badge-btn"
        data-for={project.id}
      >
        <i
          className={classnames('material-icons', style.delete)}
          onClick={(): void => deleteBadge(index)}
        >
          delete
        </i>
      </span>
      <Tooltip id={project.id} />
    </>
  );
};

interface ProjectBadgesProps {
  showEdit: boolean;
  project: Project;
}

/**
 * Shows a project's badges, which are typically small rectangular images showing
 * a status (e.g. CircleCI build status, CodeClimate
 * maintainability rating) and linking to the service on click.
 */
const ProjectBadges: FC<ProjectBadgesProps> = ({
  project,
  showEdit = false
}) => {
  const [isEditing, setIsEditing] = useState(false);

  return (
    <>
      <div className={style.badges}>
        {project.badges.map((badge, index) => (
          <React.Fragment key={uniqueId('project-badge')}>
            <a
              className={style.badge}
              href={badge.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div>
                <img src={badge.imgSrc} alt="Badge" />
              </div>
            </a>
            {isEditing ? (
              <DeleteBadgeButton index={index} project={project} />
            ) : null}
          </React.Fragment>
        ))}

        {showEdit ? (
          <AddBadgeButton
            onClick={(): void => setIsEditing(true)}
            project={project}
          />
        ) : null}
      </div>

      {isEditing ? (
        <ProjectBadgeForm
          project={project}
          onSuccess={(): void => setIsEditing(false)}
          onCancel={(): void => setIsEditing(false)}
        />
      ) : null}
    </>
  );
};

export default ProjectBadges;
