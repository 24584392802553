import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withAuth } from '@creditcards/auth0-react';
import Chip from '@creditcards/ui-kit-react/lib/components/Chip';
import {
  Menu,
  MenuItem,
  MenuDivider
} from '@creditcards/ui-kit-react/lib/components/Menu';
import PropTypes from 'prop-types';
// import color from 'utils/color';
// import classnames from 'classnames';

import style from './style.module.css';
import VersionTag from '../VersionTag';
import getUserRole from '../../utils/getUserRole';

class UserDropdown extends Component {
  static propTypes = {
    auth: PropTypes.shape({
      tenant: PropTypes.string,
      token: PropTypes.shape({
        idTokenPayload: PropTypes.shape({
          picture: PropTypes.string
        })
      })
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func,
      replace: PropTypes.func
    }).isRequired,
    user: PropTypes.shape({
      displayName: PropTypes.string,
      roles: PropTypes.array
    }).isRequired,
    logout: PropTypes.func.isRequired
  };

  state = {
    menuActive: false
  };

  getUserRole() {
    const {
      user: { roles }
    } = this.props;

    return getUserRole(roles);
  }

  render() {
    const { menuActive } = this.state;
    const {
      user,
      logout,
      history,
      auth: { tenant, token }
    } = this.props;

    // const rgba = `rgba(${color(tenant, false).join(',')}, 0.08)`;

    return (
      <div className={style.wrap}>
        <div
          className={style.profile}
          onClick={() => this.setState({ menuActive: !menuActive })}
          data-cy="user-drop-down"
        >
          {token.idTokenPayload ? (
            <div className={style.picture}>
              <img src={token.idTokenPayload.picture} alt="Shield" />
            </div>
          ) : null}
          <div className={style.username}>
            <span>{user.displayName}</span>
            <br />
            {tenant ? (
              <span className={style.currentTenant}>{tenant}</span>
            ) : null}
          </div>
          {tenant ? (
            <Chip className={style.userRole}>{this.getUserRole()}</Chip>
          ) : null}
          <i className="material-icons">expand_more</i>
        </div>
        <Menu
          active={menuActive}
          position="topRight"
          className={style.menu}
          onHide={() => this.setState({ menuActive: false })}
        >
          <MenuItem
            caption={`${tenant ? 'Switch' : 'Select'} Tenant`}
            className={style.menuItem}
            onClick={() => history.push('/select-tenant')}
          />
          <MenuItem
            className={style.menuItem}
            caption="Logout"
            onClick={logout}
          />
          <MenuDivider />
          <MenuItem disabled className={style.menuItem}>
            <VersionTag />
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

export default withRouter(withAuth(UserDropdown));
