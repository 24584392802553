import React, { Component } from 'react';
import classnames from 'classnames';
import { Alert, Button, Chip } from '@creditcards/ui-kit-react';
import { capitalize } from 'lodash';
import PropTypes from 'prop-types';
import style from './style.module.css';
import Protected from '../Protected';
import {
  DELETE_WEB_HOOK,
  GET_ENV
} from '../../containers/Environments/queries';
import WebHookEdit from './Edit';
import { makeButton } from '../DeleteButton';
import getWebHookType from './util'

const DeleteWebHookButton = makeButton(DELETE_WEB_HOOK);

class WebHook extends Component {
  static propTypes = {
    webHook: PropTypes.shape({
      id: PropTypes.string,
      url: PropTypes.string,
      on: PropTypes.arrayOf(PropTypes.string)
    }).isRequired,
    environment: PropTypes.shape({
      id: PropTypes.string,
      url: PropTypes.string
    }).isRequired,
    newWebHookId: PropTypes.string.isRequired,
    newSigningKey: PropTypes.string.isRequired
  };

  state = {
    editing: false,
    copied: false
  };

  render() {
    const { webHook, environment, newWebHookId, newSigningKey } = this.props;
    const { editing, copied } = this.state;

    const webHookType = getWebHookType(webHook.url)

    return (
      <React.Fragment key={webHook.id}>
        <div className={style.hook}>
          <div className={style.hookUrlWrap}>
            <div className={style.hookUrl}>
              <i className="material-icons">link</i>
              <span>{webHook.url}</span>
            </div>
            <Protected hasRole="USER">
              <div className={style.actions}>
                <i
                  onClick={e => {
                    e.preventDefault();
                    this.setState({ editing: webHook.id });
                  }}
                  className={classnames('material-icons', style.edit)}
                  data-cy="edit-web-hook-btn"
                >
                  edit
                </i>
                <DeleteWebHookButton
                  buttonProps={{
                    'data-cy': 'delete-web-hook-btn'
                  }}
                  variables={{ id: webHook.id }}
                  refetchQueries={[
                    { query: GET_ENV, variables: { id: environment.id } }
                  ]}
                />
              </div>
            </Protected>

            {newSigningKey && newWebHookId === webHook.id && webHookType === "https" && (
              <Alert
                className={style.keyAlert}
                message={
                  <div>
                    <strong>SAVE THIS KEY - IT WILL NOT BE SHOWN AGAIN</strong>
                    <div className={style.signingKeyWrap}>
                      <div className={style.key}>
                        <code>{newSigningKey}</code>
                      </div>
                      <div>
                        <Button
                          appearance={copied ? 'success' : 'primary'}
                          raised
                          small
                          onClick={e => {
                            e.preventDefault();
                            navigator.clipboard.writeText(newSigningKey);
                            this.setState({ copied: true });
                          }}
                        >
                          {copied ? 'Copied' : 'Copy'}
                        </Button>
                      </div>
                    </div>
                    <p>
                      Please use this key to verify the integrity of messages
                      sent to this web hook.
                    </p>
                    <a
                      href="https://shield.rvdocs.io/docs/features/web-hooks.html"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Learn more about web hook verification here.
                    </a>
                  </div>
                }
                type="info"
              />
            )}
            <div className={style.on}>
              {webHook.on.map(status => (
                <Chip>{capitalize(status)}</Chip>
              ))}
            </div>
          </div>
        </div>
        {editing ? (
          <WebHookEdit
            onClose={() => this.setState({ editing: null })}
            webHook={webHook}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

export default WebHook;
