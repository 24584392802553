import React, { createRef, FC } from 'react';
import Button from '@creditcards/ui-kit-react/lib/components/Button';
import { Form } from '@creditcards/react-forms/lib';
import { get } from 'lodash';
import TextInput from 'components/Form/TextInput';
import extractFieldError from 'utils/gqlErrors';
import { SlackReminder } from 'generated/graphql';
import { ApolloError } from 'apollo-boost';

import style from '../SlackAlertForm/style.module.css';

interface Props {
  onSubmit: (form: { channel: string }) => void;
  onCancel: () => void;
  reminder?: SlackReminder;
  error: ApolloError | undefined;
  edit?: boolean;
  loading: boolean;
}

const SlackReminderForm: FC<Props> = ({
  onSubmit,
  onCancel,
  reminder,
  edit = false,
  error,
  loading,
}) => {
  const form = createRef<HTMLFormElement>();

  return (
    <Form
      // @ts-ignore
      className={style.form}
      onValidSubmit={onSubmit}
      // @ts-ignore
      ref={form}
    >
      <TextInput
        name="channel"
        type="text"
        placeholder="#rvatx-tech"
        className={style.input}
        defaultValue={get(reminder, 'channel')}
        error={extractFieldError(error, 'channel')}
        required
      />

      <div className={style.actions}>
        <Button
          raised
          small
          appearance="primary"
          // @ts-ignore
          disabled={loading}
          onClick={(e) => {
            e.preventDefault();

            form.current?.submit()}
          }
        >
          {edit ? 'Update' : 'Create'}
        </Button>

        <Button flat small appearance="danger" onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </Form>
  );
};

export default SlackReminderForm;
