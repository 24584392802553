import React, { Component } from 'react';
import { Card, CardContent, CardHead, Alert } from '@creditcards/ui-kit-react';
import PropTypes from 'prop-types';
import style from '../Form/style.module.css';
import {
  GET_PROJECT_GROUP,
  REMOVE_PROJECT_FROM_GROUP
} from '../../../containers/ProjectGroups/queries';
import { makeButton } from '../../DeleteButton';
import AddProjectToGroupButton from '../AddProjectToGroupButton';

const RemoveProjectButton = makeButton(REMOVE_PROJECT_FROM_GROUP);

class ProjectGroupProjectsList extends Component {
  static propTypes = {
    projectGroup: PropTypes.shape({
      id: PropTypes.string,
      projects: PropTypes.array
    }).isRequired
  };

  render() {
    const { projectGroup } = this.props;

    return (
      <Card>
        <CardHead>Projects in This Group</CardHead>
        <CardContent>
          {projectGroup.projects.length === 0 && (
            <Alert
              type="warning"
              className={style.noProjects}
              message={
                <React.Fragment>
                  There are no projects in this group.
                  <AddProjectToGroupButton
                    projectGroup={projectGroup}
                    flat={false}
                    raised
                    small
                  />
                </React.Fragment>
              }
            />
          )}
          <ul className={style.projects}>
            {projectGroup.projects.map(p => (
              <li key={p.id}>
                <div>{p.name}</div>
                <div>
                  <RemoveProjectButton
                    buttonIcon="remove"
                    variables={{
                      projectGroupId: projectGroup.id,
                      projectId: p.id
                    }}
                    refetchQueries={[
                      {
                        query: GET_PROJECT_GROUP,
                        variables: { id: projectGroup.id }
                      }
                    ]}
                  />
                </div>
              </li>
            ))}
          </ul>
        </CardContent>
      </Card>
    );
  }
}

export default ProjectGroupProjectsList;
