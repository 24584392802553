import React, { Component } from 'react';
import Button from '@creditcards/ui-kit-react/lib/components/Button/index';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Mutation } from '@apollo/react-components';
import {
  CREATE_DEPLOYMENT,
  LATEST_DEPLOYMENT
} from '../../../containers/Environments/queries';
import TagSelector from '../../TagSelector';
import style from '../style.module.css';

class DeployTool extends Component {
  static propTypes = {
    environment: PropTypes.shape({
      id: PropTypes.string.isRequired,
      lastDeployment: PropTypes.shape({
        artifactName: PropTypes.string
      }),
      tagPattern: PropTypes.string
    }).isRequired,
    project: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired,
    // This silences an erroneous PropType error related to cloning elements
    // in <Window>
    // eslint-disable-next-line react/require-default-props
    changePane: PropTypes.func,
    setError: PropTypes.func.isRequired
  };

  state = {
    selectedTag: null
  };

  render() {
    const { environment, project, changePane, setError } = this.props;
    const { selectedTag } = this.state;

    return (
      <React.Fragment>
        <TagSelector
          currentTag={get(environment, 'lastDeployment.artifactName', '')}
          projectId={project.id}
          environmentId={environment.id}
          repoUrl={project.repository}
          onTagSelect={tag => this.setState({ selectedTag: tag })}
        />
        <div className={style.foot}>
          <Mutation
            mutation={CREATE_DEPLOYMENT}
            refetchQueries={[
              {
                query: LATEST_DEPLOYMENT,
                variables: { environmentId: environment.id }
              }
            ]}
            onError={setError}
            onCompleted={() => changePane('latest-deployment')}
          >
            {(createDeployment, { loading }) => (
              <Button
                disabled={loading || selectedTag === null}
                raised
                appearance="primary"
                onClick={() =>
                  createDeployment({
                    variables: {
                      deployment: {
                        environmentId: environment.id,
                        ref: selectedTag.sha,
                        artifactName: selectedTag.name
                      }
                    }
                  })
                }
              >
                {selectedTag === null ? 'Select a Tag' : 'Deploy'}
              </Button>
            )}
          </Mutation>
          <Button
            raised
            appearance="danger"
            onClick={() => changePane('latest-deployment')}
          >
            Cancel
          </Button>
        </div>
      </React.Fragment>
    );
  }
}

export default DeployTool;
