import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { SortableHandle } from 'react-sortable-hoc';
import style from '../style.module.css';

/**
 * This button acts as the drag handle for the environment boxes that you can
 * drag and drop to re-order. It manually hides the tooltip during dragging
 * to fix a visual glitch.
 */
class MoveButton extends Component {
  ref = React.createRef();

  static propTypes = {
    isDragging: PropTypes.bool.isRequired
  };

  componentDidUpdate() {
    const { isDragging } = this.props;
    if (isDragging) {
      ReactTooltip.hide(this.ref);
    }
  }

  render() {
    return (
      <i
        className={classnames('material-icons', style.moveButton)}
        data-tip="Move environment"
        ref={r => {
          this.ref = r;
        }}
      >
        drag_indicator
      </i>
    );
  }
}

export default SortableHandle(MoveButton);
