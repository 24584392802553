import React from 'react';
import _ from 'lodash';
import style from './style.module.css';

interface GitRefProps {
  repo?: string;
  repoRef: string;
  url?: string;
}

const GitRef: React.FC<GitRefProps> = ({ repoRef, repo = '', url = '' }) => (
  <a
    className={style.link}
    href={url || `${_.trimEnd(repo, '/')}/tree/${repoRef}`}
    target="_blank"
    rel="noopener noreferrer"
  >
    {repoRef.substring(0, 8)}
    <i className="material-icons">launch</i>
  </a>
);

export default GitRef;
