import React, { Component } from 'react';
import PropTypes from 'prop-types';
import EnvLatestDeploymentDetails from 'components/EnvLatestDeploymentDetails';
import { Mutation } from '@apollo/react-components';
import { withRouter } from 'react-router-dom';
import Button from '@creditcards/ui-kit-react/lib/components/Button/index';
import { get } from 'lodash';
import { CANCEL_DEPLOYMENT } from 'containers/Environments/queries';
import style from '../style.module.css';
import Protected from '../../Protected';

class LatestDeployment extends Component {
  static propTypes = {
    environment: PropTypes.shape({
      id: PropTypes.string,
      privileged: PropTypes.bool,
      providerConfiguration: PropTypes.object
    }).isRequired,
    project: PropTypes.shape({
      repository: PropTypes.string
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired
    }).isRequired,
    onStatusChange: PropTypes.func,
    changePane: PropTypes.func.isRequired,
    setError: PropTypes.func.isRequired
  };

  static defaultProps = {
    onStatusChange: () => {
      // do nothing
    }
  };

  supportsCancelation = () => {
    const { environment } = this.props;
    return environment.providerConfiguration?.__typename === 'EcsConfiguration';
  };

  renderCancelButton = () => {
    if (!this.supportsCancelation()) return null;

    const status = get(this.props, 'environment.lastDeployment.status');
    const id = get(this.props, 'environment.lastDeployment.id');

    if (!id || status !== 'STARTED') return null;

    const {
      environment: { privileged },
      setError
    } = this.props;

    return (
      <Protected hasRole={privileged ? 'DEPLOYER' : 'USER'}>
        <Mutation
          mutation={CANCEL_DEPLOYMENT}
          variables={{ id }}
          onError={setError}
        >
          {(mutate, { loading }) => (
            <Button
              raised
              appearance="danger"
              disabled={loading}
              onClick={() => mutate()}
              icon={privileged ? 'lock' : null}
            >
              Cancel
            </Button>
          )}
        </Mutation>
      </Protected>
    );
  };

  renderDeployButton = () => {
    const status = get(this.props, 'environment.lastDeployment.status');
    if (status === 'STARTED' && this.supportsCancelation()) return null;

    const inProgress = ['PENDING', 'STARTED', 'CANCELING'].includes(status);

    const {
      changePane,
      environment: { privileged, providerConfiguration }
    } = this.props;

    if (!providerConfiguration) {
      return null;
    }

    return (
      <Protected hasRole={privileged ? 'DEPLOYER' : 'USER'}>
        <Button
          raised
          appearance="primary"
          disabled={inProgress}
          onClick={() => changePane('deploy-tool')}
          icon={privileged ? 'lock' : null}
        >
          Deploy
        </Button>
      </Protected>
    );
  };

  render() {
    const { environment, history } = this.props;

    return (
      <React.Fragment>
        <EnvLatestDeploymentDetails {...this.props} />

        <div className={style.foot}>
          <Button
            flat
            appearance="primary"
            onClick={() => history.push(`../environments/${environment.id}`)}
          >
            View
          </Button>
          {this.renderCancelButton()}
          {this.renderDeployButton()}
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(LatestDeployment);
