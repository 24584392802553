import React, { Component } from 'react';
import { MutationLoading } from 'components/LoadingPage';
import Alert from '@creditcards/ui-kit-react/lib/components/Alert';
import PropTypes from 'prop-types';
import { CREATE_WEB_HOOK, GET_ENV } from 'containers/Environments/queries';
import { getStatusEnumsFromForm } from 'utils/enums';
import WebHookForm from './Form';

import style from './style.module.css';

class WebHookInlineAdd extends Component {
  static propTypes = {
    environmentId: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired
  };

  render() {
    const { onClose, environmentId } = this.props;

    return (
      <div className={style.container} data-cy="web-hook-form">
        <MutationLoading
          mutation={CREATE_WEB_HOOK}
          onCompleted={({ createWebHook }) => onClose(createWebHook)}
          refetchQueries={[
            { query: GET_ENV, variables: { id: environmentId } }
          ]}
        >
          {(createWebHook, { error, loading }) => (
            <React.Fragment>
              {error ? <Alert type="danger" message={error.message} /> : null}
              <div className={style.heading}>New Alert</div>
              <WebHookForm
                gqlError={error}
                loading={loading}
                onCancel={onClose}
                onSubmit={form =>
                  createWebHook({
                    variables: {
                      webHook: {
                        url: form.url,
                        on: getStatusEnumsFromForm(form.on),
                        environmentId,
                        awsProfileId: form.awsProfileId || null
                      }
                    }
                  })
                }
              />
            </React.Fragment>
          )}
        </MutationLoading>
      </div>
    );
  }
}

export default WebHookInlineAdd;
