import React, { Component } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import TextInput from '../../Form/TextInput';
import extractFieldError from '../../../utils/gqlErrors';

class ProjectGroupForm extends Component {
  static propTypes = {
    projectGroup: PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string
    }),
    gqlError: PropTypes.shape({
      graphQLErrors: PropTypes.array
    })
  };

  static defaultProps = {
    projectGroup: {},
    gqlError: null
  };

  getError = field => {
    const { gqlError } = this.props;
    return extractFieldError(gqlError, field);
  };

  render() {
    const { projectGroup } = this.props;

    return (
      <React.Fragment>
        <TextInput
          label="Group Name"
          name="name"
          required
          defaultValue={get(projectGroup, 'name')}
          error={this.getError('name')}
        />
        <TextInput
          label="Description"
          name="description"
          required
          multiline
          rows={5}
          defaultValue={get(projectGroup, 'description')}
          error={this.getError('description')}
        />
      </React.Fragment>
    );
  }
}

export default ProjectGroupForm;
