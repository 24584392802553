import React, { Component } from 'react';
import classnames from 'classnames';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import localforage from 'localforage';
import moment from 'moment';
import {
  GetLatestReleaseNoteProps,
  withGetLatestReleaseNote
} from 'generated/graphql';

import style from './style.module.css';

interface Props extends GetLatestReleaseNoteProps, RouteComponentProps {}

interface State {
  notify: boolean;
  setFromLs: boolean;
}

class ReleaseNotesIndicator extends Component<Props, State> {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    notify: false,
    setFromLs: false
  };

  componentDidUpdate = () => {
    const { data } = this.props;

    const { setFromLs } = this.state;

    if (
      data &&
      !setFromLs &&
      !data.loading &&
      !data.error &&
      data.releaseNotes &&
      data.releaseNotes[0]
    ) {
      const [latestReleaseNote] = data.releaseNotes;
      const latest = latestReleaseNote.insertedAt || 0;

      localforage.getItem<string>('shield:release_notes').then(lastViewed => {
        const notify = lastViewed
          ? moment(latest).isAfter(moment(lastViewed))
          : true;

        // eslint-disable-next-line react/destructuring-assignment
        if (this.state.notify !== notify) {
          this.setState({ notify, setFromLs: true });
        }
      });
    }
  };

  render() {
    const { history } = this.props;
    const { notify } = this.state;

    return (
      <span
        onClick={() => {
          this.setState({ notify: false });
          history.push('/release-notes');
        }}
        className={classnames('material-icons', style.releaseNotes, {
          [style.notify]: notify
        })}
      >
        notifications
      </span>
    );
  }
}

export default withGetLatestReleaseNote()(withRouter(ReleaseNotesIndicator));
