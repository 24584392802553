import { toUpper, transform } from 'lodash';

export const getStatusEnumsFromForm = statuses =>
  transform(
    statuses,
    (result, v, k) => {
      if (v === true) result.push(toUpper(k));

      return result;
    },
    []
  );

/**
 * Returns a humanized version of the Enum value.
 *
 * Example:
 *   > humanize('US_WEST_2');
 *   > 'us-west-2'
 *
 * @param {String} - value
 * @returns {String}
 */
export const humanize = value =>
  value && value.toLowerCase().replace(/_/g, '-');

/**
 * Returns all enum values for the AwsRegion enum.
 *
 * @returns {Array<String>}
 */
export const awsRegions = [
  'CA_CENTRAL_1',
  'EU_CENTRAL_1',
  'EU_WEST_1',
  'EU_WEST_2',
  'US_EAST_1',
  'US_EAST_2',
  'US_WEST_1',
  'US_WEST_2',
  'SA_EAST_1'
];
