import React, { Component } from 'react';
import Alert from '@creditcards/ui-kit-react/lib/components/Alert';
import PropTypes from 'prop-types';
import { UPDATE_SLACK_ALERT } from 'containers/Environments/queries';
import SlackAlertForm from 'components/SlackAlertForm';
import { MutationLoading } from 'components/LoadingPage';
import { getStatusEnumsFromForm } from 'utils/enums';

import style from './style.module.css';

class SlackAlertInlineEdit extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    alert: PropTypes.shape({
      id: PropTypes.string,
      channel: PropTypes.string,
      on: PropTypes.array
    }).isRequired
  };

  render() {
    const { onClose, alert } = this.props;

    return (
      <div className={style.container}>
        <MutationLoading mutation={UPDATE_SLACK_ALERT} onCompleted={onClose}>
          {(updateSlackAlert, { error }) => (
            <React.Fragment>
              {error ? <Alert type="danger" message={error.message} /> : null}
              <div className={style.heading}>Edit Alert</div>
              <SlackAlertForm
                edit
                gqlError={error}
                onCancel={onClose}
                alert={alert}
                onSubmit={form =>
                  updateSlackAlert({
                    variables: {
                      id: alert.id,
                      slackAlert: {
                        channel: form.channel,
                        on: getStatusEnumsFromForm(form.on)
                      }
                    }
                  })
                }
              />
            </React.Fragment>
          )}
        </MutationLoading>
      </div>
    );
  }
}

export default SlackAlertInlineEdit;
