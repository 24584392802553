import React, { Component } from 'react';
import Button from '@creditcards/ui-kit-react/lib/components/Button';
import { Form } from '@creditcards/react-forms/lib';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import TextInput from 'components/Form/TextInput';
import extractFieldError from 'utils/gqlErrors';

import style from './style.module.css';
import DeploymentStatusOptions from '../DeploymentStatusOptions';

class SlackAlertForm extends Component {
  form = React.createRef();

  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func
    }).isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    alert: PropTypes.shape({
      channel: PropTypes.string,
      on: PropTypes.array
    }),
    edit: PropTypes.bool,
    gqlError: PropTypes.shape({
      graphQLErrors: PropTypes.array
    })
  };

  static defaultProps = {
    edit: false,
    alert: {
      on: []
    },
    gqlError: null
  };

  getError = field => {
    const { gqlError } = this.props;
    return extractFieldError(gqlError, field);
  };

  render() {
    const { onSubmit, onCancel, alert, edit } = this.props;
    return (
      <Form
        className={style.form}
        onValidSubmit={onSubmit}
        ref={el => {
          this.form = el;
        }}
      >
        <TextInput
          name="channel"
          type="text"
          placeholder="#rvatx-tech"
          className={style.input}
          defaultValue={get(alert, 'channel')}
          error={this.getError('channel')}
          required
        />
        <DeploymentStatusOptions selected={alert.on} />
        <div className={style.actions}>
          <Button
            raised
            small
            appearance="primary"
            onClick={() => this.form.submit()}
          >
            {edit ? 'Update' : 'Create'}
          </Button>

          <Button flat small appearance="danger" onClick={onCancel}>
            Cancel
          </Button>
        </div>
      </Form>
    );
  }
}

export default SlackAlertForm;
