import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { filterByIntegrated, dashboardUrl } from 'utils/new-relic';

import { Button, Menu, MenuItem } from '@creditcards/ui-kit-react';

import styles from './style.module.css';

const LabelMenuItem = props => (
  <MenuItem
    disabled
    theme={{
      caption: styles.label,
      menuItem: styles.labelItem
    }}
    {...props}
  />
);

const NoLinksMenuItem = () => (
  <MenuItem
    caption="None"
    disabled
    theme={{
      caption: styles.noLinks,
      menuItem: styles.noLinksItem
    }}
  />
);

class ProjectLinks extends Component {
  static propTypes = {
    project: PropTypes.shape({
      environments: PropTypes.arrayOf(PropTypes.object),
      name: PropTypes.string,
      references: PropTypes.arrayOf(PropTypes.object),
      repository: PropTypes.string
    }).isRequired
  };

  state = {
    open: false
  };

  renderNewRelic = envs => {
    const newRelicEnvs = filterByIntegrated(envs);
    return (
      <>
        {newRelicEnvs.map(env => (
          <MenuItem
            key={env.id}
            caption={env.name}
            link={{
              href: dashboardUrl(
                env.newRelicAccount.accountId,
                env.newRelicAppId
              ),
              target: '_blank',
              rel: 'noopener noreferrer'
            }}
            icon="open_in_new"
          />
        ))}
        {newRelicEnvs.length === 0 && <NoLinksMenuItem />}
      </>
    );
  };

  render() {
    const { open } = this.state;
    const { project } = this.props;

    return (
      <React.Fragment>
        <Button
          flat
          appearance="primary"
          onClick={() => this.setState({ open: !open })}
          className={styles.button}
          icon="keyboard_arrow_down"
        >
          Links
        </Button>
        <div className={styles.menu}>
          <Menu
            active={open}
            position="auto"
            onHide={() => this.setState({ open: false })}
          >
            <LabelMenuItem caption="Repository" />
            <MenuItem
              icon="code"
              caption={project.name}
              link={{
                href: project.repository,
                target: '_blank',
                rel: 'noopener noreferrer'
              }}
            />
            <LabelMenuItem caption="Environments" />
            {project.environments.map(env => (
              <MenuItem
                disabled={env.url == null}
                icon="open_in_new"
                caption={env.name}
                key={env.id}
                link={{
                  href: env.url,
                  target: '_blank',
                  rel: 'noopener noreferrer'
                }}
              />
            ))}
            {project.environments.length === 0 && <NoLinksMenuItem />}
            <LabelMenuItem caption="New Relic" />
            {this.renderNewRelic(project.environments)}
            <LabelMenuItem caption="References" />
            {project.references.map(ref => (
              <MenuItem
                icon="open_in_new"
                caption={ref.name}
                key={ref.id}
                link={{
                  href: ref.url,
                  target: '_blank',
                  rel: 'noopener noreferrer'
                }}
              />
            ))}
            {project.references.length === 0 && <NoLinksMenuItem />}
          </Menu>
        </div>
      </React.Fragment>
    );
  }
}

export default ProjectLinks;
