import React, { Component } from 'react';
import Container from 'components/Container';
import Title from 'components/Title';
import { QueryLoading } from 'components/LoadingPage';
import { SEARCH_PROJECTS } from 'containers/Projects/queries';
import { debounce } from 'lodash';
import { withAuth } from '@creditcards/auth0-react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Input,
  CardHead,
  CardContent
} from '@creditcards/ui-kit-react';
import { withRoutedPagination } from 'components/RoutedPagination';
import Pagination from 'components/Pagination';
import styles from './style.module.css';
import ProjectGroups from '../../../components/ProjectGroups/List';
import CreateProjectButton from '../../../components/CreateProjectButton';
import ProjectBox from '../../../components/ProjectBox';
import AccessNotice from '../../../components/AccessNotice';

class ProjectsList extends Component {
  state = {
    search: ''
  };

  static propTypes = {
    auth: PropTypes.shape({ hasRole: PropTypes.func.isRequired }).isRequired,
    // This is technically required, but PropTypes doesn't recognize it coming
    // from RoutedPagination
    changePage: PropTypes.func,
    page: PropTypes.number,
    history: PropTypes.shape({
      push: PropTypes.func
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        tenant: PropTypes.string.isRequired
      }).isRequired
    }).isRequired
  };

  static defaultProps = {
    changePage: () => {
      // do nothing
    },
    page: 1
  };

  updatePage = pageNumber => {
    const { changePage } = this.props;

    changePage(pageNumber);
  };

  renderSearch = () => {
    const { changePage } = this.props;
    const { search } = this.state;
    const searchFn = debounce(value => {
      changePage(1);
      this.setState({ search: value });
    }, 200);

    return (
      <Input
        placeholder="Search Projects"
        defaultValue={search}
        onChange={searchFn}
        icon="search"
      />
    );
  };

  renderTable = ({ error, data }) => {
    if (error) return `Error! ${error.message}`;

    const renderPagination = () => {
      const hasProjects = data.searchProjects.entries.length > 0;
      return (
        hasProjects && (
          <Pagination
            settings={{
              total: data.searchProjects.pagination.totalEntries,
              pageSize: data.searchProjects.pagination.pageSize,
              currentPage: data.searchProjects.pagination.pageNumber
            }}
            nextPage={this.updatePage}
          />
        )
      );
    };

    return (
      <div data-cy="projects-list">
        <div className={styles.projectsHeading}>
          <div className={styles.heading}>All Projects</div>
          <div>{renderPagination()}</div>
        </div>
        <Row>
          {data.searchProjects.entries.map(project => (
            <Col sm={4} className={styles.col} key={project.id}>
              <ProjectBox project={project} />
            </Col>
          ))}
          {data.searchProjects.entries.length === 0 && (
            <div className={styles.noProjects}>No projects</div>
          )}
        </Row>
        {renderPagination()}
      </div>
    );
  };

  render() {
    const {
      auth,
      page,
      match: {
        params: { tenant }
      }
    } = this.props;
    const { search } = this.state;

    return (
      <Container>
        <Title title="Projects" />

        <CardHead>
          <div className={styles.head}>
            <div className={styles.title}>
              <span className={styles.tenant}>
                {`${tenant.replace('_', ' ')} `}
              </span>
              Projects
            </div>
            <div className={styles.search}>{this.renderSearch()}</div>
            {auth.hasRole('USER') ? (
              <div className={styles.addProject}>
                <CreateProjectButton />
              </div>
            ) : null}
          </div>
        </CardHead>
        <AccessNotice />
        <CardContent>
          <ProjectGroups />
          <QueryLoading query={SEARCH_PROJECTS} variables={{ page, search }}>
            {this.renderTable}
          </QueryLoading>
        </CardContent>
      </Container>
    );
  }
}

export default withAuth(withRoutedPagination(ProjectsList));
