/* eslint-disable max-classes-per-file */
import React, { Component } from 'react';
import { Query, Mutation, Subscription } from '@apollo/react-components';
import hoistNonReactStatic from 'hoist-non-react-statics';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import logo from 'assets/logo.svg';
import style from './style.module.css';

class LoadingPage extends Component {
  render() {
    return (
      <div className={style.container}>
        <img className={classnames(style.i1)} src={logo} alt="logo" />
        <img className={classnames(style.i2)} src={logo} alt="logo" />
        <img className={classnames(style.i3)} src={logo} alt="logo" />

        <h2>Loading</h2>
      </div>
    );
  }
}

const TIME_TO_RENDER = 500;
const MIN_RENDER_TIME = 1500;

// eslint-disable-next-line react/no-multi-comp
class LoadingController extends Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    children: PropTypes.func.isRequired
  };

  state = {
    /**
     * If we *must* be rendering the loading component.
     * @property {boolean}
     */
    renderLoading: false,
    /**
     * If we are able to render the content block.
     * @property {boolean}
     */
    renderContent: false
  };

  static getDerivedStateFromProps(props, state) {
    const { loading } = props;

    // if we get a loading prop, and we are currently rendering content, stop rendering content
    if (loading && state.renderContent) {
      return {
        renderLoading: false,
        renderContent: false
      };
    }

    return state;
  }

  componentDidMount = () => {
    const { loading } = this.props;

    if (loading) {
      this.resetRenderTimes();
    } else {
      this.setState({ renderContent: true });
    }
  };

  componentWillUnmount = () => {
    clearTimeout(this.loadingDelay);
  };

  componentDidUpdate = prevProps => {
    const { loading } = this.props;
    const { renderLoading } = this.state;
    const now = new Date().getTime();

    if (prevProps.loading && !loading) {
      // we were loading, and we finished
      clearTimeout(this.loadingDelay);

      if (!renderLoading) {
        // we weren't showing the loading indicator, so just render the content
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ renderContent: true });
      } else if (now < this.hideLoadingAt) {
        // we were showing the loading indicator, we need to delay hiding it

        setTimeout(this.stopLoading.bind(this), this.hideLoadingAt - now);
      } else {
        // loading took longer than MIN_RENDER_TIME, no delay needed
        this.stopLoading();
      }
    } else if (!prevProps.loading && loading) {
      // start the loading process over
      this.resetRenderTimes();
    }
  };

  resetRenderTimes = () => {
    const now = new Date().getTime();
    this.hideLoadingAt = now + TIME_TO_RENDER + MIN_RENDER_TIME;

    this.loadingDelay = setTimeout(() => {
      this.setState({ renderLoading: true });
    }, TIME_TO_RENDER);
  };

  stopLoading = () => {
    this.setState({
      renderLoading: false,
      renderContent: true
    });
  };

  render() {
    const { children } = this.props;
    const { renderContent, renderLoading } = this.state;

    if (renderLoading) {
      return <LoadingPage />;
    }

    if (renderContent) {
      return children();
    }

    return null;
  }
}

export const withLoading = BaseComponent => {
  const wrapped = ({ children, ...props }) => (
    <BaseComponent {...props}>
      {(...options) => {
        const { loading } = options.length === 1 ? options[0] : options[1];

        return (
          <LoadingController loading={loading}>
            {() => children(...options)}
          </LoadingController>
        );
      }}
    </BaseComponent>
  );

  wrapped.displayName = `withLoading(${BaseComponent.displayName ||
    BaseComponent.name})`;
  wrapped.WrappedComponent = BaseComponent;
  wrapped.propTypes = {
    children: PropTypes.func.isRequired
  };

  return hoistNonReactStatic(wrapped, BaseComponent);
};

export default LoadingPage;
export const QueryLoading = withLoading(Query);
export const MutationLoading = withLoading(Mutation);
export const SubscriptionLoading = withLoading(Subscription);
